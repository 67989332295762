import { formatDateToYYYYMMDDForInput } from '@/utils/date/date';
import { Box, Button, Flex, HStack, IconButton, Switch, Text } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { BiArrowToLeft, BiSortDown } from 'react-icons/bi';
import { MdGroups, MdSearch as SearchIcon } from 'react-icons/md';

import {
  CustomFieldOptionType,
  OrganizedFilterItemsType,
} from '@/common/components/CommonOrganizedFilterWrapper';
import MultiLayerFilter from '@/common/components/MultiLayerFilter';
import SearchBar from '@/common/components/SearchBar';
import { SuspenseWithSpinner } from '@/common/components/SuspenseWithSpinner';
import { generateItemValueBySelectOption } from '@/common/components/customFields';
import { OptionValue } from '@/common/types';
import { useApplicationContext } from '@/context/ApplicationContext';
import { SortType, useWorkOrderPageContext } from '@/context/WorkOrderPageContext';
import { type WorkOrderStatus } from '@/graphql/types';
import { AssetMultiLayerFilter } from '@/modules/assets/components/AssetMultiLayerFilter';
import { useGroups } from '@/modules/groups';
import { useHierarchicalProducts } from '@/modules/products';
import { useWorkOrderStatus } from '@/modules/workOrders/hooks/useWorkOrderStatus';
import useTranslation from '@/utils/i18n/useTranslation';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import { gql } from '@apollo/client';
import { FaTasks } from 'react-icons/fa';
import { PAGE_LIMIT } from './WorkOrderCardListTabs';
import WorkOrderDatePickerButton from './WorkOrderDatePickerButton';
import {
  useWorkOrderFilterQuery,
  useWorkOrderFilterSuspenseQuery,
} from './WorkOrderFilter.generated';
import WorkOrderFilterIconButton from './WorkOrderFilterIconButton';
import WorkOrderOrganizedFilter, {
  defaultOrganizedFilterItemsOpenValue,
  filterValuesKeysType,
} from './WorkOrderOrganizedFilter';

export type WorkOrderSortBy = 'createdAt' | 'dueDate' | 'updatedAt';

export type FilterFieldProps = {
  from?: string;
  to?: string;
  statuses?: string[];
  otherFilters?: string[];
  assetIds?: number[];
  productIds?: number[];
  customFieldOptionIds?: number[];
  createdByIds?: string[];
  assigneeIds?: string[];
  groupIds?: number[];
  searchField?: string;
  isDone?: boolean;
  page?: number;
  limit?: number;
  ordering?: SortType;
  priorities?: string[];
  hasCheckList?: boolean;
  hasRequest?: boolean;
  sortBy?: WorkOrderSortBy;
  stoppageReasonIds?: number[];
};

type FilterType = 'all' | 'withChecklist' | 'withoutChecklist' | 'withRequest' | 'withoutRequest';

type FilterValuesParams = {
  hasCheckList?: boolean;
  hasRequest?: boolean;
};

const otherFilterValues = {
  hasPart: 'hasPart',
  hasAttachment: 'hasAttachment',
  hasComment: 'hasComment',
};

const sortByValues: { value: WorkOrderSortBy; labelKey: string }[] = [
  {
    value: 'createdAt',
    labelKey: 'created-at',
  },
  {
    value: 'dueDate',
    labelKey: 'due-date',
  },
  {
    value: 'updatedAt',
    labelKey: 'last-updated-at',
  },
];

const filterValues: { value: FilterType; labelKey: string }[] = [
  { value: 'all', labelKey: 'all' },
  { value: 'withChecklist', labelKey: 'with-checklist' },
  { value: 'withoutChecklist', labelKey: 'without-checklist' },
  { value: 'withRequest', labelKey: 'created-with-request' },
  { value: 'withoutRequest', labelKey: 'created-without-request' },
];

const getInitialFilterValue = (filterValues: FilterValuesParams): FilterType => {
  const { hasCheckList, hasRequest } = filterValues;

  if (hasCheckList !== undefined) return hasCheckList ? 'withChecklist' : 'withoutChecklist';
  if (hasRequest !== undefined) return hasRequest ? 'withRequest' : 'withoutRequest';

  return DEFAULT_FILTER;
};

const otherFilterItems = Object.keys(otherFilterValues).map((key) => ({
  value: otherFilterValues[key as filterValuesKeysType],
}));

const DEFAULT_SORT_BY: WorkOrderSortBy = 'createdAt';
const DEFAULT_FILTER: FilterType = 'all';

const customFieldOptionsMap = new Map<number, number[]>();

gql`
  query WorkOrderFilter {
    users {
      id
      name
    }
    workOrderCustomFields {
      ...WorkOrderCustomFieldFragment
    }
  }
`;

const WorkOrderFilter = () => {
  const {
    paramsHandler,
    fetchDoneWorkOrdersWithFilter,
    changeApplyMyGroupFilter,
    applyMyGroupFilter,
  } = useWorkOrderPageContext();
  const { selectProductItems } = useHierarchicalProducts();

  const { companySetting } = useApplicationContext();
  const { t } = useTranslation();

  const data = useWorkOrderFilterQuery();
  const customFieldOptions: CustomFieldOptionType[] = (() => {
    const customFields = data.data?.workOrderCustomFields || [];
    return customFields
      .filter((customField) => customField.type === 'select')
      .map((customField) => ({
        label: customField.label,
        customFieldId: customField.id,
        selectOptions: customField.selectItems.map(generateItemValueBySelectOption),
      }));
  })();

  const {
    startDate,
    endDate,
    statuses = [],
    otherFilters = [],
    assetIds = [],
    productIds = [],
    customFieldOptionIds = [],
    createdByIds = [],
    assigneeIds = [],
    groupIds = [],
    stoppageReasonIds = [],
    searchField = '',
    setStartDate,
    setEndDate,
    setStatuses,
    setAssetIds,
    setProductIds,
    setCustomFieldOptionIds,
    setCreatedByIds,
    setAssigneeIds,
    setGroupIds,
    setSearchField,
    setOtherFilters,
    setStoppageReasonIds,
    isDone,
    priorities = [],
    setPriorities,
    sortBy = undefined,
    setSortBy,
    hasCheckList,
    setHasCheckList,
    hasRequest,
    setHasRequest,
  } = paramsHandler;

  const initialOrganizedFilterItemsOpenValue = useMemo(() => {
    const initialValue = defaultOrganizedFilterItemsOpenValue;
    customFieldOptions.forEach((customFieldOption) => {
      initialValue[customFieldOption.customFieldId] = false;
    });

    return initialValue;
  }, [customFieldOptions]);

  const [organizedFilterItemsOpenValue, setOrganizedFilterItemsOpenValue] =
    useState<OrganizedFilterItemsType>(initialOrganizedFilterItemsOpenValue);
  const [filterValue, setFilterValue] = useState<FilterType>(
    getInitialFilterValue({ hasCheckList, hasRequest })
  );
  const [isShowSearchBar, setIsShowSearchBar] = useState(false);
  const [isOrganizedFilterOpen, setIsOrganizedFilterOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(searchField);
  const { isMobile, isDesktop } = useScreenInfos();
  const { getStatusLabel } = useWorkOrderStatus();

  const defaultStatusLabel = t('status.task-status');

  //URL paramsから取得した値をMapに格納
  customFieldOptionIds.forEach((optionId) => {
    const customFieldOption = customFieldOptions.find((option) =>
      option.selectOptions.some((o) => o.id === optionId)
    );
    if (!customFieldOption) return;
    const { customFieldId } = customFieldOption;
    const options = customFieldOptionsMap.get(customFieldId) || [];
    customFieldOptionsMap.set(customFieldId, [...new Set([...options, optionId])]);
  });

  const statusLabel = useMemo(() => {
    if (statuses.length === 0) {
      return defaultStatusLabel;
    } else if (statuses.length === 1) {
      const statusTitle = getStatusLabel(statuses[0] as WorkOrderStatus);
      return statusTitle;
    } else {
      return `${defaultStatusLabel}`;
    }
  }, [defaultStatusLabel, getStatusLabel, statuses]);

  const handleDoneFilterChange = (params: FilterFieldProps) => {
    fetchDoneWorkOrdersWithFilter({ ...params, page: 1, limit: PAGE_LIMIT });
  };

  const preventOrganizedFilterClose = (value: OrganizedFilterItemsType) => {
    setIsOrganizedFilterOpen(true);
    setOrganizedFilterItemsOpenValue(value);
  };

  const filterDateChange = (dates: [Date?, Date?]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (!start || !end) return;
    if (isDone && start && end) {
      handleDoneFilterChange({
        from: formatDateToYYYYMMDDForInput(start),
        to: formatDateToYYYYMMDDForInput(end),
      });
    }
  };

  const filterStatusChange = (statuses: string[]) => {
    preventOrganizedFilterClose({
      ...initialOrganizedFilterItemsOpenValue,
      status: true,
    });
    setStatuses(statuses);
    if (isDone) {
      handleDoneFilterChange({
        statuses,
      });
    }
  };

  const filterOtherChange = (otherFilters: string[]) => {
    preventOrganizedFilterClose({
      ...initialOrganizedFilterItemsOpenValue,
      others: true,
    });
    setOtherFilters(otherFilters);
    if (isDone) {
      handleDoneFilterChange({
        otherFilters,
      });
    }
  };
  const filterPrioritiesChange = (priorities: string[]) => {
    preventOrganizedFilterClose({
      ...initialOrganizedFilterItemsOpenValue,
      priority: true,
    });
    setPriorities(priorities);
    if (isDone) {
      handleDoneFilterChange({
        priorities,
      });
    }
  };
  const filterSortByChange = (sortBy: WorkOrderSortBy) => {
    setSortBy(sortBy);
    if (isDone) {
      handleDoneFilterChange({
        sortBy,
      });
    }
  };

  const filterChange = (filterValue: FilterType) => {
    setFilterValue(filterValue);

    const isChecklistFilter = filterValue.includes('Checklist');
    const isRequestFilter = filterValue.includes('Request');
    const hasCheckList = isChecklistFilter ? filterValue === 'withChecklist' : undefined;
    const hasRequest = isRequestFilter ? filterValue === 'withRequest' : undefined;

    setHasCheckList(hasCheckList);
    setHasRequest(hasRequest);

    if (isDone) {
      handleDoneFilterChange({
        hasCheckList,
        hasRequest,
      });
    }
  };

  const filterAssetIds = (ids?: OptionValue[]) => {
    let newAssetIds: number[] = [];
    if (!ids) {
      setAssetIds([]);
    } else {
      newAssetIds = ids.map((id) => Number(id));
      setAssetIds(newAssetIds);
    }

    if (isDone) {
      handleDoneFilterChange({
        assetIds: newAssetIds,
      });
    }
  };

  const filterProductIds = (ids?: OptionValue[]) => {
    let newProductIds: number[] = [];
    if (!ids) {
      setProductIds([]);
    } else {
      newProductIds = ids.map((id) => Number(id));
      setProductIds(newProductIds);
    }

    if (isDone) {
      handleDoneFilterChange({
        productIds: newProductIds,
      });
    }
  };

  const filterCustomFieldOptionIds = (customFieldId: number, ids?: OptionValue[]) => {
    preventOrganizedFilterClose({
      ...initialOrganizedFilterItemsOpenValue,
      [customFieldId]: true,
    });

    let newCustomFieldOptionIds: number[] = [];
    customFieldOptionsMap.set(customFieldId, ids ? ids.map((id) => Number(id)) : []);
    customFieldOptionsMap.forEach((value) => {
      newCustomFieldOptionIds = [...newCustomFieldOptionIds, ...value];
    });

    setCustomFieldOptionIds(newCustomFieldOptionIds);

    if (isDone) {
      handleDoneFilterChange({
        customFieldOptionIds: newCustomFieldOptionIds,
      });
    }
  };

  const filterCreatedByIds = (ids?: OptionValue[]) => {
    preventOrganizedFilterClose({
      ...initialOrganizedFilterItemsOpenValue,
      creator: true,
    });
    let newCreatedByIds: string[] = [];
    if (!ids) {
      setCreatedByIds([]);
    } else {
      newCreatedByIds = ids.map((id) => String(id));
      setCreatedByIds(newCreatedByIds);
    }
    if (isDone) {
      handleDoneFilterChange({
        createdByIds: newCreatedByIds,
      });
    }
  };

  const filterByAssigneeIds = (ids?: OptionValue[]) => {
    let newAssigneeIds: string[] = [];
    if (!ids) {
      setAssigneeIds([]);
    } else {
      newAssigneeIds = ids.map((id) => String(id));
      setAssigneeIds(newAssigneeIds);
    }
    if (isDone) {
      handleDoneFilterChange({
        assigneeIds: newAssigneeIds,
      });
    }
  };

  const filterByGroupIds = (ids: OptionValue[] = []) => {
    const newGroupIds = ids.map((id) => Number(id));
    setGroupIds(newGroupIds);

    if (isDone) {
      handleDoneFilterChange({
        groupIds: newGroupIds,
      });
    }
  };

  const resetOrganizedFilter = () => {
    if (!isDone) {
      setStatuses([]);
    }
    setCreatedByIds([]);
    setPriorities([]);
    setOtherFilters([]);
    setIsOrganizedFilterOpen(false);
    setStoppageReasonIds([]);
    setCustomFieldOptionIds([]);
    customFieldOptionsMap.clear();
  };

  const resetOrganizedFilterItemsOpenValue = () => {
    setOrganizedFilterItemsOpenValue(initialOrganizedFilterItemsOpenValue);
  };

  const resetAllFilter = () => {
    if (!isDone) {
      setStatuses([]);
    }
    filterSortByChange(DEFAULT_SORT_BY);
    filterDateChange([undefined, undefined]);
    filterChange(DEFAULT_FILTER);
    setSearchField('');
    setSearchValue('');
    setAssetIds([]);
    setProductIds([]);
    setCustomFieldOptionIds([]);
    setCreatedByIds([]);
    setAssigneeIds([]);
    setGroupIds([]);
    setOtherFilters([]);
    setPriorities([]);
    setStoppageReasonIds([]);
    customFieldOptionsMap.clear();
    if (isDone) {
      handleDoneFilterChange({
        statuses: [],
        assetIds: [],
        productIds: [],
        from: undefined,
        to: undefined,
        searchField: '',
        otherFilters: [],
        priorities: [],
        customFieldOptionIds: [],
        groupIds: [],
        stoppageReasonIds: [],
      });
    }
  };

  const showClearButton = useMemo(() => {
    if (isDone) {
      return (
        startDate ||
        endDate ||
        assetIds.length > 0 ||
        productIds.length > 0 ||
        assigneeIds.length > 0 ||
        groupIds.length > 0 ||
        createdByIds.length > 0 ||
        otherFilters.length > 0 ||
        priorities.length > 0 ||
        customFieldOptionIds.length > 0 ||
        stoppageReasonIds.length > 0 ||
        searchField !== ''
      );
    }
    return (
      startDate ||
      endDate ||
      statuses.length > 0 ||
      assetIds.length > 0 ||
      productIds.length > 0 ||
      assigneeIds.length > 0 ||
      groupIds.length > 0 ||
      createdByIds.length > 0 ||
      otherFilters.length > 0 ||
      priorities.length > 0 ||
      customFieldOptionIds.length > 0 ||
      stoppageReasonIds.length > 0 ||
      searchField !== ''
    );
  }, [
    isDone,
    startDate,
    endDate,
    groupIds,
    statuses.length,
    assetIds.length,
    productIds.length,
    assigneeIds.length,
    createdByIds.length,
    otherFilters.length,
    priorities.length,
    customFieldOptionIds.length,
    stoppageReasonIds.length,
    searchField,
  ]);

  const handleSearch = (searchText: string) => {
    setSearchField(searchText);
    if (isDone) {
      handleDoneFilterChange({
        searchField: searchText,
      });
    }
  };

  const usersInProjectOptions = useMemo(() => {
    return (
      data?.data?.users.map((user) => ({
        id: user.id,
        label: user.name,
      })) ?? []
    );
  }, [data?.data?.users]);

  const organizedFiltersCount = useMemo(() => {
    let selectedFiltersCount = 0;

    if (createdByIds.length > 0) selectedFiltersCount++;
    if (priorities.length > 0) selectedFiltersCount++;
    if (statuses.length > 0) selectedFiltersCount++;
    if (otherFilters.length > 0) selectedFiltersCount++;
    if (stoppageReasonIds.length > 0) selectedFiltersCount++;
    if (customFieldOptionIds.length > 0) {
      customFieldOptionsMap.forEach((optionIds) => {
        if (optionIds.some((optionId) => customFieldOptionIds.includes(optionId))) {
          selectedFiltersCount++;
        }
      });
    }

    return selectedFiltersCount;
  }, [
    createdByIds.length,
    otherFilters.length,
    priorities.length,
    statuses.length,
    stoppageReasonIds.length,
    customFieldOptionIds,
  ]);

  const { selectGroups } = useGroups();

  const groups = useMemo(() => {
    if (!selectGroups) return [];
    return selectGroups.map(({ value, label }) => ({ id: Number(value), label }));
  }, [selectGroups]);

  return (
    <Box
      p={2}
      sx={{
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        overflowY: 'hidden',
      }}
    >
      <HStack spacing='12px'>
        <Box m={0} p={0} minW={isShowSearchBar ? '250px' : 'auto'}>
          {isMobile &&
            (isShowSearchBar ? (
              <Flex
                sx={{
                  marginInline: '0px !important',
                  margin: '0px !important',
                }}
              >
                <Box border='1px solid' borderColor='neutral.300' borderRadius='md'>
                  <SearchBar
                    value={searchValue}
                    setValue={setSearchValue}
                    onSearch={handleSearch}
                  />
                </Box>
                <IconButton
                  size='sm'
                  as='div'
                  fontSize={16}
                  variant='ghost'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  aria-label='Clear'
                  color='neutral.500'
                  border='none'
                  icon={<BiArrowToLeft />}
                  onClick={() => setIsShowSearchBar(false)}
                />
              </Flex>
            ) : (
              <Box border='1px solid' borderColor='neutral.300' borderRadius='md'>
                <IconButton
                  size='sm'
                  as='div'
                  variant='ghost'
                  fontSize={16}
                  aria-label='Show Search Bar'
                  color='neutral.500'
                  alignSelf='stretch'
                  icon={<SearchIcon />}
                  onClick={() => setIsShowSearchBar(true)}
                />
              </Box>
            ))}

          {isDesktop && (
            <SearchBar value={searchValue} setValue={setSearchValue} onSearch={handleSearch} />
          )}
        </Box>
        <WorkOrderFilterIconButton
          icon={<BiSortDown fontSize={20} />}
          isActive={sortBy !== DEFAULT_SORT_BY}
          value={sortBy}
          values={sortByValues}
          onChange={(value) => filterSortByChange(value as WorkOrderSortBy)}
          onReset={() => filterSortByChange(DEFAULT_SORT_BY)}
        />
        <WorkOrderFilterIconButton
          icon={<FaTasks fontSize={20} />}
          isActive={filterValue !== DEFAULT_FILTER}
          value={filterValue}
          values={filterValues}
          onChange={(value) => filterChange(value as FilterType)}
          onReset={() => filterChange(DEFAULT_FILTER)}
        />
        {companySetting?.accessGroup && (
          <MultiLayerFilter
            customSettingComponent={
              <HStack justifyContent='space-between' m={2}>
                <Text size='sm'> {t('belonging-groups-only')}</Text>
                <Switch
                  onChange={() => changeApplyMyGroupFilter()}
                  isChecked={applyMyGroupFilter}
                  size='sm'
                />
              </HStack>
            }
            icon={<MdGroups size='32px' />}
            label={t('group')}
            values={groupIds}
            options={groups}
            onChange={filterByGroupIds}
          />
        )}
        <WorkOrderDatePickerButton
          dates={[startDate, endDate]}
          onChange={(dates) => filterDateChange(dates as [Date?, Date?])}
          onReset={() => filterDateChange([undefined, undefined])}
          defaultLabel={t('date.created-date')}
        />
        <SuspenseWithSpinner>
          <AssetMultiLayerFilter values={assetIds} onChange={filterAssetIds} />
        </SuspenseWithSpinner>
        {companySetting?.accessProduct && (
          <MultiLayerFilter
            label={t('pages.product')}
            values={productIds}
            options={selectProductItems}
            onChange={filterProductIds}
          />
        )}
        <MultiLayerFilter
          label={t('assignee')}
          values={assigneeIds}
          options={usersInProjectOptions}
          onChange={filterByAssigneeIds}
        />
        <WorkOrderOrganizedFilter
          statusLabel={statusLabel}
          filterOtherChange={filterOtherChange}
          filterCreatedByIds={filterCreatedByIds}
          resetOrganizedFilter={resetOrganizedFilter}
          organizedFiltersCount={organizedFiltersCount}
          filterPrioritiesChange={filterPrioritiesChange}
          filteredOtherFilterItems={otherFilterItems}
          organizedFilterItemsOpenValue={organizedFilterItemsOpenValue}
          filterStatusChange={filterStatusChange}
          resetOrganizedFilterItemsOpenValue={resetOrganizedFilterItemsOpenValue}
          createdByIds={createdByIds}
          otherFilters={otherFilters}
          priorities={priorities}
          statuses={statuses}
          isDone={isDone}
          isOpen={isOrganizedFilterOpen}
          setIsOpen={setIsOrganizedFilterOpen}
          setOrganizedFilterItemsOpenValue={setOrganizedFilterItemsOpenValue}
          customFieldOptions={customFieldOptions}
          customFieldOptionsMap={customFieldOptionsMap}
          filterCustomFieldOptionIds={filterCustomFieldOptionIds}
          stoppageReasonIds={stoppageReasonIds}
          setStoppageReasonIds={setStoppageReasonIds}
        />
        {showClearButton && (
          <Button variant='ghost' colorScheme='primary' size='sm' onClick={() => resetAllFilter()}>
            {t('actions.clear')}
          </Button>
        )}
      </HStack>
    </Box>
  );
};

export default WorkOrderFilter;
