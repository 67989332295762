import { ApolloError } from '@apollo/client';
import { Box, Center, Flex, Spinner } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';

import WorkOrderCardListTabs, {
  WorkOrderActionPayload,
} from '@/components/workOrders/WorkOrderCardListTabs';
import { UpdateWorkOrderCustomFieldFileInput, type WorkOrderStatus } from '@/graphql/types';

import SimpleSpinner from '@/common/components/SimpleSpinner';
import { Maybe } from '@/common/types';
import WorkOrderDetailAndForm from '@/components/workOrders/WorkOrderDetailAndForm';
import { useWorkOrderTextSuggestModal } from '@/components/workOrders/WorkOrderTextSuggestModal';
import WorkOrderToolbar from '@/components/workOrders/WorkOrderToolbar';
import { CustomFieldFileValueType } from '@/components/workOrders/customFields/CustomFieldFileValueType';
import { useApplicationContext } from '@/context/ApplicationContext';
import { useConfirmModal } from '@/context/ConfirmModalContext';
import {
  CreateWorkOrderProvider,
  WorkOrderFormDefaultValuesType,
  useCreateWorkOrder,
} from '@/context/CreateWorkOrderContext';
import { useGraphqlContext } from '@/context/GraphqlContext';
import { useLoading } from '@/context/LoadingContext';
import { useTemplateContext } from '@/context/TemplateContext';
import { WorkOrderPageProvider, useWorkOrderPageContext } from '@/context/WorkOrderPageContext';
import { ICreateCheckListInput, IUpdateCheckListInput } from '@/modules/checkList';
import {
  useAddCheckListMutation,
  useRemoveCheckListMutation,
  useUpdateCheckListMutation,
} from '@/modules/checkList/graphql/checkLists.generated';
import { useSuggestWorkOrderTextMutation } from '@/modules/workOrders/graphql/suggest.generated';
import { useGenerateCopyingWorkOrderInputReferenceMutation } from '@/modules/workOrders/graphql/workOrderCopyingRefernce.generated';
import { useWorkOrderTemplateByIdLazyQuery } from '@/modules/workOrders/graphql/workOrderTemplates.generated';
import {
  useAddWorkOrderCustomFieldFileValuesMutation,
  usePushWorkOrderNotificationMutation,
  useRemoveWorkOrderCustomFieldFileValueMutation,
  useRemoveWorkOrderMutation,
  useUpdateWorkOrderCustomFieldFileValueMutation,
  useUpdateWorkOrderMutation,
  useUpdateWorkOrderPartMutation,
  useUpdateWorkOrderStatusMutation,
  useWorkOrderLazyQuery,
} from '@/modules/workOrders/graphql/workOrders.generated';
import {
  type WorkOrderFormDataType,
  useAddWorkOrder,
} from '@/modules/workOrders/hooks/useAddWorkOrder';
import { useWorkOrderStatus } from '@/modules/workOrders/hooks/useWorkOrderStatus';
import { type IWorkOrder, WorkOrderDoneStatus } from '@/modules/workOrders/types/workOrder';
import type {
  IWorkOrderTemplate,
  IWorkOrderTemplateBase,
} from '@/modules/workOrders/types/workOrderTemplate';
import { findWorkOrderIdByOffset } from '@/modules/workOrders/utils/findWorkOrderIdByOffset';
import { toastPromiseOptions, useToast } from '@/utils/atoms/toast';
import { deepCopy } from '@/utils/copy/copy';
import { WorkOrderCSVType, useWorkOrderCSV } from '@/utils/csv/useWorkOrderCSV';
import { useFiles } from '@/utils/file/useFiles';
import useTranslation from '@/utils/i18n/useTranslation';
import { useListAndDetail } from '@/utils/mobiles/useListAndDetail';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import { useNavigate, useSearchParams } from '@remix-run/react';

const newWorkOrderHash = 'newWorkOrder';

const Index: React.FC = () => {
  const { confirm } = useConfirmModal();
  const { companySetting, me } = useApplicationContext();
  const {
    defaultWorkOrderTemplate: template,
    workOrderTemplates,
    isLoadingWorkOrderTemplate,
  } = useTemplateContext();
  const { createWorkOrderCSV } = useWorkOrderCSV();
  const { getStatusLabel } = useWorkOrderStatus();
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const { toast, toastPromise } = useToast();
  const { loading } = useLoading();
  const { evictObjectFromCache } = useGraphqlContext();
  const { t, t_errors, t_toasts } = useTranslation();
  const [workOrderTextSuggestModal, openWorkOrderTextSuggestModal] = useWorkOrderTextSuggestModal();
  const { getFileUploadUrls, setFileUrlToSrcFiles } = useFiles();
  const { isDesktop } = useScreenInfos();

  const {
    doneWorkOrders = [],
    targetWorkOrder,
    setTargetWorkOrder,
    findFirstNotDoneWorkOrder,
    isDone,
    updateApolloQueryCache,
    notDoneWorkOrdersMap,
  } = useWorkOrderPageContext();

  const [isEditing, setIsEditing] = useState(false);
  const [isNeededToSetInitialWorkOrder, setIsNeededToSetInitialWorkOrder] = useState(false);

  const [getDataWorkOrder, { loading: isWorkOrderQueryLoading }] = useWorkOrderLazyQuery({
    onCompleted: (data) => {
      const workOrder = data.workOrder as IWorkOrder;
      //  オブジェクトの関連を消す
      setTargetWorkOrder(workOrder);
    },
    onError: (error) => {
      setShowDetailForMobile(false);
      if (
        error &&
        error.graphQLErrors.some((errorWorkOrder) => errorWorkOrder.extensions?.code === '404')
      ) {
        toast({
          title: t_toasts('failed.task.target-task-does-not-exist'),
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    },
  });

  const isWorkOrderLoading = isWorkOrderQueryLoading || isLoadingWorkOrderTemplate;
  const { addWorkOrder } = useAddWorkOrder();
  const { createWorkOrderInput } = useCreateWorkOrder();

  const [editWorkOrder] = useUpdateWorkOrderMutation();
  const [removeWorkOrder] = useRemoveWorkOrderMutation({
    onCompleted: (data, options: Maybe<{ variables?: { id?: number } }>) => {
      // Update cache after work order is deleted
      // TODO: Implement proper cache clearing for pagination
      if (data && options && options.variables) {
        const id = options.variables.id;
        evictObjectFromCache && evictObjectFromCache(`WorkOrder:${id}`);
      }
    },
  });
  const [addWorkOrderCustomFieldFileValues] = useAddWorkOrderCustomFieldFileValuesMutation();
  const [updateWorkOrderCustomFieldFileValue] = useUpdateWorkOrderCustomFieldFileValueMutation();
  const [removeWorkOrderCustomFieldFileValue] = useRemoveWorkOrderCustomFieldFileValueMutation();
  const [updateWorkOrderPart] = useUpdateWorkOrderPartMutation();
  const [updateWorkOrderStatus] = useUpdateWorkOrderStatusMutation();
  const [pushWorkOrderNotification] = usePushWorkOrderNotificationMutation();
  const [suggestWorkOrderText] = useSuggestWorkOrderTextMutation();
  const [addCheckList] = useAddCheckListMutation();
  const [removeCheckList] = useRemoveCheckListMutation();
  const [updateCheckList] = useUpdateCheckListMutation();

  const { showDetail, showList, showMobileDetail, setShowDetailForMobile, setUrlHash } =
    useListAndDetail();

  const [isFirstHashChecked, setIsFirstHashChecked] = useState(true);

  const [customFieldFileValues, setCustomFieldFileValues] = useState<
    (CustomFieldFileValueType & { id?: number })[]
  >([]);

  const workOrderCreateButtonClicked = (assetId?: number) => {
    displayCreateWorkOrderForm({ assetId });
  };

  const displayCreateWorkOrderForm = async (
    preWorkOrderData?: WorkOrderFormDefaultValuesType,
    customFieldFileValues: CustomFieldFileValueType[] | undefined = undefined,
    preWorkOrderTemplate: IWorkOrderTemplateBase | undefined = undefined
  ) => {
    setCustomFieldFileValues([]);
    setIsEditing(false);
    setUrlHash(newWorkOrderHash);
    if (!template) throw new Error(t_errors('task.create-task-without-template'));
    await createWorkOrderInput({
      submit: createWorkOrder,
      cancel: cancelWorkOrderCreation,
      template: preWorkOrderTemplate,
      workOrder: preWorkOrderData,
      customFieldFileValues: customFieldFileValues,
      displayStatus: true,
    });
  };

  const createWorkOrder = async (payload: WorkOrderFormDataType): Promise<boolean> => {
    if (!template) throw new Error(t_errors('task.create-task-without-template'));
    const workOrder = await addWorkOrder({ ...payload });
    if (!workOrder) return false;
    setUrlHash(workOrder.id.toString());

    updateApolloQueryCache(workOrder);

    setTargetWorkOrder(workOrder);
    return true;
  };

  const updateWorkOrder =
    // TODO: updateの引数確認
    // biome-ignore lint/suspicious/noExplicitAny: any
    async (payload: any) => {
      loading(true);

      try {
        if (targetWorkOrder === undefined) throw Error(t_errors('task.task-does-not-exist'));
        delete payload.templateId;
        const { data } = await editWorkOrder({
          variables: {
            updateWorkOrderInput: {
              id: targetWorkOrder.id,
              title: targetWorkOrder.title,
              assetId: targetWorkOrder.asset ? targetWorkOrder.asset.id : undefined,
              description: targetWorkOrder.description,
              status: targetWorkOrder.status,
              ...payload,
              // 個別で更新しているため、ここでは更新しない。
              customFieldFileValues: [],
            },
          },
        });
        checkUpdateSuccessOrError(data?.updateWorkOrder as IWorkOrder);
      } finally {
        loading(false);
      }
    };

  const changeWorkOrderStatusDone = async ({ id }: WorkOrderActionPayload) => {
    const { data } = await updateWorkOrderStatus({
      variables: { updateWorkOrderStatusInput: { id, status: WorkOrderDoneStatus } },
    });

    checkUpdateSuccessOrError(data?.updateWorkOrderStatus as IWorkOrder, false);
  };

  const checkUpdateSuccessOrError = (updatedWorkOrder: IWorkOrder, isFormClose = true) => {
    if (!updatedWorkOrder) {
      toast({
        title: t_toasts('failed.task.update-task-failed'),
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    setTargetWorkOrder(updatedWorkOrder);

    updateApolloQueryCache(updatedWorkOrder);

    if (isFormClose) {
      setIsEditing(false);
    }

    toast({
      title: t_toasts('success.task.task-updated'),
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
  };

  const displayUpdateWorkOrderForm = () => {
    setIsEditing(true);
  };

  const deleteWorkOrder = () => {
    if (targetWorkOrder === undefined) throw Error(t_errors('task.task-does-not-exist'));

    confirmAndRemoveWorkOrder(targetWorkOrder.id, targetWorkOrder.status);
  };

  const deleteWorkOrderById = ({ id, status }: WorkOrderActionPayload) => {
    const isResetTargetAfterDelete = targetWorkOrder?.id === id;
    confirmAndRemoveWorkOrder(id, status, isResetTargetAfterDelete);
  };

  const confirmAndRemoveWorkOrder = async (id: number, status: WorkOrderStatus, reset = true) => {
    const result = await confirm(
      t('confirmation.task.delete-task'),
      t('warning.delete'),
      t('confirmation.delete')
    );

    if (!result) return;

    await removeWorkOrder({ variables: { id } });

    const isDone = status === WorkOrderDoneStatus;
    const newTargetWorkOrder = isDone
      ? doneWorkOrders.find((workOrder) => workOrder.id !== id)
      : findFirstNotDoneWorkOrder();

    if (reset) {
      setUrlHash('');
      if (newTargetWorkOrder) {
        getDataWorkOrder({ variables: { id: newTargetWorkOrder.id } });
      } else {
        setTargetWorkOrder(undefined);
      }
    }

    toast({
      title: t_toasts('success.task.task-deleted'),
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
  };

  const createWorkOrderCustomFieldFiles = async (fileValues: CustomFieldFileValueType[]) => {
    if (targetWorkOrder === undefined) throw Error(t_errors('task.task-does-not-exist'));

    const { data } = await addWorkOrderCustomFieldFileValues({
      variables: {
        newWorkOrderCustomFieldFilesInput: {
          workOrderId: targetWorkOrder.id,
          fileValues: fileValues.map(({ customFieldId, fileId, name, contentType }) => ({
            customFieldId,
            fileId,
            name,
            contentType,
          })),
        },
      },
    });

    toast({
      title: t_toasts('success.file.file-created'),
      status: 'success',
      duration: 9000,
      isClosable: true,
    });

    return fileValues.map((value) => {
      const valueId = data?.addWorkOrderCustomFieldFileValues?.customFieldFileValues?.find(
        ({ fileId }) => value.fileId === fileId
      )?.id;

      if (!valueId) {
        throw Error('value not found');
      }

      return {
        ...value,
        id: valueId,
        key: valueId.toString(),
      };
    });
  };

  const deleteWorkOrderCustomFieldFile = async (fileId: string, customFieldId: number) => {
    if (!targetWorkOrder) throw new Error(t_errors('impossible'));
    await removeWorkOrderCustomFieldFileValue({
      variables: {
        deleteWorkOrderCustomFieldFileInput: {
          workOrderId: targetWorkOrder.id,
          customFieldId,
          fileId,
        },
      },
    });
    toast({
      title: t_toasts('success.file.file-deleted'),
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
  };

  const updateWorkOrderCustomFieldFile = async (input: UpdateWorkOrderCustomFieldFileInput) => {
    if (targetWorkOrder === undefined) throw Error(t_errors('task.task-does-not-exist'));

    await updateWorkOrderCustomFieldFileValue({
      variables: {
        updateWorkOrderCustomFieldFileInput: input,
      },
    });

    toast({
      title: t_toasts('success.file.file-updated'),
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
  };

  const onUpdatePart = async (partId: number, quantity: number) => {
    if (!targetWorkOrder) throw new Error(t_errors('impossible'));
    try {
      const { data } = await updateWorkOrderPart({
        variables: {
          updateWorkOrderPartInput: {
            workOrderId: targetWorkOrder.id,
            partId,
            quantity,
          },
        },
      });
      if (data) {
        setTargetWorkOrder(data.updateWorkOrderPart as IWorkOrder);
      }
    } catch (error) {
      if (error instanceof ApolloError) {
        const conflictError = error.graphQLErrors.find((error) => error.extensions?.code === '409');
        if (conflictError) {
          toast({
            title: conflictError.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
          return;
        }
      }

      throw error;
    }
  };

  const handleNotificationButtonClick = async () => {
    if (targetWorkOrder === undefined) throw Error(t_errors('task.task-does-not-exist'));

    const result = await confirm(t('confirmation.notify-everyone'), undefined, t('yes'), {
      colorScheme: 'primary',
    });
    if (!result) return;

    const { data } = await pushWorkOrderNotification({
      variables: { id: targetWorkOrder.id },
    });

    if (!data) {
      toast({
        title: t_toasts('failed.push-notification-failed'),
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    toast({
      title: data.pushWorkOrderNotification
        ? t_toasts('success.notification-sent')
        : t_toasts('failed.no-user-to-notify'),
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
  };

  const [generateCopyingWorkOrderInputReference] =
    useGenerateCopyingWorkOrderInputReferenceMutation();
  const [workOrderTemplateByIdQuery] = useWorkOrderTemplateByIdLazyQuery();
  const handleCopyWorkOrderButtonClick = async (workOrderId: number) => {
    if (targetWorkOrder === undefined) throw Error(t_errors('task.task-does-not-exist'));

    const [reference, template] = await toastPromise(
      (async () => {
        const response = await generateCopyingWorkOrderInputReference({
          variables: { workOrderId },
        });

        if (response.errors || !response.data) {
          throw response.errors;
        }

        const reference = response.data.generateCopyingWorkOrderInputReference;
        const template = workOrderTemplates.find(
          (template) => template.id === reference.templateId
        );

        // requestやscheduleの時はtemplateがないので、templateIdから取得する
        if (template) {
          return [reference, template];
        } else {
          const templateByIdResponse = await workOrderTemplateByIdQuery({
            variables: { id: reference.templateId! },
          });

          if (templateByIdResponse.error || !templateByIdResponse.data) {
            throw templateByIdResponse.error;
          }

          return [reference, templateByIdResponse.data.workOrderTemplateById as IWorkOrderTemplate];
        }
      })(),
      toastPromiseOptions(t)
    );

    displayCreateWorkOrderForm(reference, reference.customFieldFileValues, template);
  };

  const createCheckList = async (checkList: ICreateCheckListInput): Promise<number> => {
    if (!targetWorkOrder) throw new Error('Impossible!');
    const data = await addCheckList({
      variables: {
        newCheckListInput: { workOrderId: targetWorkOrder.id, templateId: checkList.templateId },
      },
    });
    toast({
      title: t_toasts('success.check-list.check-list-added'),
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    if (!data.data) throw new Error('Impossible!');
    return data.data.addCheckList;
  };

  const saveCheckList = async (checkList: IUpdateCheckListInput) => {
    if (!targetWorkOrder) throw new Error('Impossible!');
    const data = await updateCheckList({
      variables: {
        updateCheckListInput: { ...checkList },
      },
    });
    // TODO: getDataWorkOrderを呼び出さないようにする
    const { data: dataWorkOrder } = await getDataWorkOrder({
      variables: { id: targetWorkOrder.id },
      fetchPolicy: 'no-cache',
    });
    if (dataWorkOrder) setTargetWorkOrder(dataWorkOrder.workOrder as IWorkOrder);
    toast({
      title: t_toasts('success.check-list.check-list-saved'),
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    if (!data.data) throw new Error('Impossible!');
  };

  const deleteCheckList = useCallback(
    async (checkListId: number) => {
      if (!targetWorkOrder) throw new Error('Impossible!');
      await removeCheckList({
        variables: { id: checkListId },
      });
      toast({
        title: t_toasts('success.check-list.check-list-deleted'),
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    },
    [removeCheckList, targetWorkOrder, toast, t_toasts]
  );

  const setWorkOrderToDetail = async (id: number) => {
    // キャッシュを書き換えれるようになったら、リストから持って
    await getDataWorkOrder({
      variables: { id },
      fetchPolicy: 'no-cache',
    });
    setIsEditing(false);
    setShowDetailForMobile(true);
    setUrlHash(id.toString());
  };

  const cancelWorkOrderCreation = () => {
    setIsEditing(false);
    setUrlHash('');
  };

  const onAddCustomFieldFileValues = async (
    fileValues: CustomFieldFileValueType[]
  ): Promise<boolean> => {
    const customFieldLocalValues = deepCopy(customFieldFileValues);

    const _fileValues = await createWorkOrderCustomFieldFiles(fileValues);
    setCustomFieldFileValues([...customFieldLocalValues, ..._fileValues]);

    return true;
  };

  const onRemoveCustomFieldFileValue = async (
    fileId: string,
    customFieldId: number
  ): Promise<boolean> => {
    const result = await confirm(
      t('confirmation.delete-file'),
      t('warning.delete'),
      t('confirmation.delete')
    );
    if (!result) return false;

    deleteWorkOrderCustomFieldFile(fileId, customFieldId);

    setCustomFieldFileValues((values) => {
      const index = values.findIndex((value) => value.fileId === fileId);
      if (index === -1) return [...values];
      values.splice(index, 1);
      return [...values];
    });

    return true;
  };

  const onUpdateCustomFieldFileValue = async (
    fileId: string,
    params: {
      fileId: string;
      contentType: string;
      name: string;
      src: string;
    }
  ): Promise<void> => {
    const customFieldLocalValues = deepCopy(customFieldFileValues);
    const valueId = customFieldFileValues.find((value) => value.fileId === fileId)?.id;

    if (!valueId) {
      throw new Error('value not found');
    }

    const { src, ...updateParams } = params;

    await updateWorkOrderCustomFieldFile({
      valueId,
      ...updateParams,
    });

    setCustomFieldFileValues(
      customFieldLocalValues.map((value) =>
        value.fileId === fileId
          ? {
              ...value,
              ...updateParams,
              src,
            }
          : value
      )
    );
  };

  const onAiVoiceSubmit = (preWorkOrderData: WorkOrderFormDefaultValuesType) => {
    displayCreateWorkOrderForm(preWorkOrderData);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: why is this isDesktop necessary?
  useEffect(() => {
    if (!me || !template || !window) return;
    const { hash } = window.location;

    if (isFirstHashChecked) {
      setIsFirstHashChecked(false);

      if (hash) {
        // hashの#を削っている
        // このあたり共通化する
        const sharpRemovedHash = hash.slice(1);
        if (sharpRemovedHash === newWorkOrderHash) {
          const newWorkOrderAssetId = query.get('newWorkOrderAssetId');
          if (newWorkOrderAssetId) {
            const assetId = parseInt(newWorkOrderAssetId.toString());
            displayCreateWorkOrderForm({ assetId });
          }
          setIsNeededToSetInitialWorkOrder(true);
        } else {
          setShowDetailForMobile(true);
          const workOrderId = Number(sharpRemovedHash);
          getDataWorkOrder({
            variables: { id: workOrderId },
          });
        }
      } else if (isDesktop) {
        setIsNeededToSetInitialWorkOrder(true);
      }
    }
  }, [getDataWorkOrder, isFirstHashChecked, query, setShowDetailForMobile, template, me]);

  const onCreateWorkOrderTextSuggest = async (
    label: string,
    text: string,
    workOrder: WorkOrderCSVType
  ) => {
    if (!companySetting) throw new Error('Impossible');
    if (!template) throw new Error('Impossible');
    const csv = createWorkOrderCSV(
      companySetting,
      template.customFields,
      [workOrder],
      getStatusLabel,
      t
    );
    let result: false | string = false;
    loading(true);
    try {
      const { data } = await suggestWorkOrderText({
        variables: {
          suggestWorkOrderTextInput: {
            data: csv,
          },
        },
      });
      loading(false);
      if (data) {
        result = await openWorkOrderTextSuggestModal(label, text, data.suggestWorkOrderText);
      }
    } catch (error) {
      toast({
        title: t_toasts('failed.AI-suggest-failed'),
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      console.error(error);
    } finally {
      loading(false);
    }
    return result;
  };

  const onTemplateSettingsClick = () => {
    navigate('/settings/work-order-template');
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: why is this isEditing necessary?
  useEffect(() => {
    if (!targetWorkOrder) return;

    setFileUrlToSrcFiles(
      targetWorkOrder.customFieldFileValues.map((value) => ({
        ...value,
        key: value.id?.toString() ?? value.fileId,
      }))
    ).then((files) => {
      setCustomFieldFileValues([...files]);
    });
  }, [isEditing, setFileUrlToSrcFiles, targetWorkOrder]);

  // TODO: 初回レンダリング時にtargetWorkOrderが無い場合、最初の作業オーダーを取得する処理を入れる。
  useEffect(() => {
    if (isNeededToSetInitialWorkOrder) {
      const workOrder = isDone ? doneWorkOrders[0] : findFirstNotDoneWorkOrder();
      if (workOrder) {
        getDataWorkOrder({ variables: { id: workOrder.id } });
      }
      if (targetWorkOrder) {
        setIsNeededToSetInitialWorkOrder(false);
      }
    }
  }, [
    doneWorkOrders,
    findFirstNotDoneWorkOrder,
    getDataWorkOrder,
    isDone,
    isNeededToSetInitialWorkOrder,
    targetWorkOrder,
  ]);

  const moveWorkOrder = (workOrderId: number, status: WorkOrderStatus, offset: number) => {
    let nextWorkOrderId: Maybe<number>;
    if (status === WorkOrderDoneStatus) {
      nextWorkOrderId = findWorkOrderIdByOffset(doneWorkOrders, workOrderId, offset);
    } else {
      notDoneWorkOrdersMap.forEach((workOrders) => {
        if (nextWorkOrderId !== undefined) return;
        nextWorkOrderId = findWorkOrderIdByOffset(workOrders, workOrderId, offset);
      });
    }

    if (nextWorkOrderId === undefined) {
      toast({
        title: t_toasts('failed.task.next-task-not-found'),
        status: 'warning',
      });
      return;
    }

    setWorkOrderToDetail(nextWorkOrderId);
  };

  const onBackButtonClicked = () => {
    setShowDetailForMobile(false);
    setUrlHash('');
  };

  return (
    <>
      {template ? (
        <WorkOrderToolbar
          showMobileDetail={showMobileDetail}
          workOrderCreateButtonClicked={workOrderCreateButtonClicked}
          accessAiVoice={companySetting?.accessAiVoice || false}
          onTemplateSettingsClick={onTemplateSettingsClick}
          workOrderTemplate={template}
          onAiVoiceSubmit={onAiVoiceSubmit}
        />
      ) : (
        <Box height='90px' borderBottom='1px' borderColor='neutral.300'>
          <SimpleSpinner />
        </Box>
      )}

      <Flex width='full' alignSelf='stretch' height={{ base: '100%', md: 'calc(100vh - 131px)' }}>
        <WorkOrderCardListTabs
          // 詳細からリストに戻ってくるときに、要素を削除すると真っ白になるユーザー(スペックが低い端末)が存在したためhiddenにする
          hidden={!showList}
          onWorkOrderClicked={setWorkOrderToDetail}
          targetWorkOrderId={targetWorkOrder ? targetWorkOrder.id : undefined}
          workOrderActions={{
            onDelete: deleteWorkOrderById,
            changeStatusDone: changeWorkOrderStatusDone,
          }}
        />

        {showDetail && (
          <WorkOrderDetailAndForm
            isLoading={isWorkOrderLoading}
            onCreateWorkOrderTextSuggest={onCreateWorkOrderTextSuggest}
            customFieldFileValues={customFieldFileValues}
            updateWorkOrder={updateWorkOrder}
            deleteWorkOrder={deleteWorkOrder}
            cancelWorkOrderCreation={cancelWorkOrderCreation}
            displayUpdateWorkOrderForm={displayUpdateWorkOrderForm}
            onUpdatePart={onUpdatePart}
            onAddCustomFieldFileValues={onAddCustomFieldFileValues}
            onRemoveCustomFieldFileValue={onRemoveCustomFieldFileValue}
            onUpdateCustomFieldFileValue={onUpdateCustomFieldFileValue}
            onClickNotificationButton={() => handleNotificationButtonClick()}
            onClickCopyWorkOrderButton={(workOrderId) =>
              handleCopyWorkOrderButtonClick(workOrderId)
            }
            workOrder={targetWorkOrder}
            isEditing={isEditing}
            getFileUploadUrls={getFileUploadUrls}
            deleteCheckList={deleteCheckList}
            createCheckList={createCheckList}
            updateCheckList={saveCheckList}
            moveWorkOrder={moveWorkOrder}
            onBackButtonClicked={onBackButtonClicked}
          />
        )}
      </Flex>

      {workOrderTextSuggestModal}
    </>
  );
};

const WorkOrderPage = () => {
  const { isApplicationLoading } = useApplicationContext();

  // TODO ここで堰き止めると後段のリクエストが全てapplication contextを待つことになるので
  // 主にグループフィルターにデフォルト値を入れたいためにapp contextが必要だけど、フィルターをサーバーサイドで保存する等工夫して、初期表示に必要な分は全て並列リクエストにしたい
  if (isApplicationLoading)
    return (
      <Center w='full' h='50vh'>
        <Spinner color='primary.500' />
      </Center>
    );
  return (
    <CreateWorkOrderProvider>
      <WorkOrderPageProvider>
        <Index />
      </WorkOrderPageProvider>
    </CreateWorkOrderProvider>
  );
};

export default WorkOrderPage;
