import SimpleSpinner from '@/common/components/SimpleSpinner';
import { WorkOrderFormDefaultValuesType } from '@/context/CreateWorkOrderContext';
import { useTemplateContext } from '@/context/TemplateContext';
import { WorkOrderStatus } from '@/graphql/types';
import { ICreateCheckListInput, IUpdateCheckListInput } from '@/modules/checkList';
import { WorkOrderFormDataType } from '@/modules/workOrders/hooks/useAddWorkOrder';
import { IWorkOrder, WorkOrderDoneStatus } from '@/modules/workOrders/types/workOrder';
import { WorkOrderCSVType } from '@/utils/csv/useWorkOrderCSV';
import { FileContentType, FileIdAndUrl } from '@/utils/file/type';
import { TASK } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import { Box } from '@chakra-ui/react';
import { FC } from 'react';
import WorkOrderDetail, { WorkOrderUpdateDataType } from './WorkOrderDetail';
import WorkOrderForm from './WorkOrderForm';
import { CustomFieldFileValueType } from './customFields/CustomFieldFileValueType';

type WorkOrderDetailAndFormProps = {
  updateWorkOrder: (workOrder: WorkOrderFormDataType | WorkOrderUpdateDataType) => void;
  deleteWorkOrder: () => void;
  onAddCustomFieldFileValues: (fileValues: CustomFieldFileValueType[]) => Promise<boolean>;
  onRemoveCustomFieldFileValue: (filedId: string, customFieldId: number) => Promise<boolean>;
  onUpdateCustomFieldFileValue: (
    fileId: string,
    params: {
      fileId: string;
      contentType: string;
      name: string;
      src: string;
    }
  ) => Promise<void>;
  cancelWorkOrderCreation: () => void;
  displayUpdateWorkOrderForm: () => void;
  workOrder?: IWorkOrder;
  isEditing: boolean;
  getFileUploadUrls: (filesContentTypes: FileContentType[]) => Promise<FileIdAndUrl[]>;
  customFieldFileValues: CustomFieldFileValueType[];
  onClickNotificationButton: () => void;
  onClickCopyWorkOrderButton: (workOrderId: number) => Promise<void>;
  onUpdatePart: (partId: number, quantity: number) => void;
  onCreateWorkOrderTextSuggest: (
    label: string,
    text: string,
    workOrder: WorkOrderCSVType
  ) => Promise<string | false>;
  createCheckList: (checkList: ICreateCheckListInput) => Promise<number>;
  deleteCheckList: (checkListId: number) => void;
  updateCheckList: (checkList: IUpdateCheckListInput) => void;
  moveWorkOrder: (workOrderId: number, status: WorkOrderStatus, offset: number) => void;
  onBackButtonClicked: () => void;
  isLoading: boolean;
};

const WorkOrderDetailAndForm: FC<WorkOrderDetailAndFormProps> = (
  props: WorkOrderDetailAndFormProps
) => {
  const {
    updateWorkOrder,
    deleteWorkOrder,
    onAddCustomFieldFileValues,
    onRemoveCustomFieldFileValue,
    onUpdateCustomFieldFileValue,
    cancelWorkOrderCreation,
    displayUpdateWorkOrderForm,
    onClickNotificationButton,
    onClickCopyWorkOrderButton,
    workOrder,
    isEditing,
    customFieldFileValues,
    getFileUploadUrls,
    onUpdatePart,
    onCreateWorkOrderTextSuggest,
    createCheckList,
    deleteCheckList,
    updateCheckList,
    moveWorkOrder,
    onBackButtonClicked,
    isLoading,
  } = props;

  const { t, t_ns } = useTranslation(TASK);
  const { isMobile } = useScreenInfos();
  const { targetWorkOrderTemplate: template } = useTemplateContext();

  const isDoneWorkOrder = workOrder?.status === WorkOrderDoneStatus;

  return (
    <Box
      h='100%'
      bg='neutral.0'
      w='full'
      overflowY={isMobile ? 'unset' : 'auto'}
      minW={{ base: 'auto', md: '450px' }}
      data-testid='work-order-detail'
    >
      {isLoading ? (
        <SimpleSpinner />
      ) : isEditing && template !== undefined && workOrder ? (
        <WorkOrderForm
          key={workOrder.id}
          // Formの時は、チェックリストは更新しないので、undefinedにする
          submit={(payload) => updateWorkOrder({ ...payload, checkLists: undefined })}
          cancel={cancelWorkOrderCreation}
          // 本来不要なマッピングなのでWorkOrder詳細がcolocating fragmentできるようになったら削除 TODO
          workOrder={workOrder as unknown as WorkOrderFormDefaultValuesType}
          template={template}
          customFieldFileValues={customFieldFileValues}
          title={t_ns('edit-task')}
          submitButton={t('actions.save')}
          onAddCustomFieldFileValues={onAddCustomFieldFileValues}
          onRemoveCustomFieldFileValue={onRemoveCustomFieldFileValue}
          onUpdateCustomFieldFileValue={onUpdateCustomFieldFileValue}
          onUpdatePart={onUpdatePart}
          deleteCheckList={deleteCheckList}
          createCheckList={createCheckList}
          displayStatus={isDoneWorkOrder}
          shouldConfirmStatusChange={true}
        />
      ) : (
        workOrder !== undefined &&
        template !== undefined && (
          <WorkOrderDetail
            key={workOrder.id}
            onCreateWorkOrderTextSuggest={onCreateWorkOrderTextSuggest}
            workOrder={workOrder}
            template={template}
            customFieldFileValues={customFieldFileValues}
            displayUpdateWorkOrderForm={displayUpdateWorkOrderForm}
            updateWorkOrder={updateWorkOrder}
            deleteWorkOrder={deleteWorkOrder}
            onAddCustomFieldFileValues={onAddCustomFieldFileValues}
            onRemoveCustomFieldFileValue={onRemoveCustomFieldFileValue}
            onUpdateCustomFieldFileValue={onUpdateCustomFieldFileValue}
            onUpdatePart={onUpdatePart}
            getFileUploadUrls={getFileUploadUrls}
            onClickNotificationButton={onClickNotificationButton}
            onClickCopyWorkOrderButton={onClickCopyWorkOrderButton}
            updateCheckList={updateCheckList}
            moveWorkOrder={moveWorkOrder}
            onBackButtonClicked={onBackButtonClicked}
          />
        )
      )}
    </Box>
  );
};

export default WorkOrderDetailAndForm;
