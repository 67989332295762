import AccordionListWrapper from '@/common/components/AccordionListWrapper';
import ListEmptyText from '@/common/components/ListEmptyText';
import SimpleSpinner from '@/common/components/SimpleSpinner';
import { useWorkOrderPageContext } from '@/context/WorkOrderPageContext';
import type { WorkOrderStatus } from '@/graphql/types';
import { useWorkOrderStatus } from '@/modules/workOrders/hooks/useWorkOrderStatus';
import { useListAccordions } from '@/utils/hooks/useListAccordions';
import { TASK } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { useListAndDetail } from '@/utils/mobiles/useListAndDetail';
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { FC, useEffect, useRef, useState } from 'react';
import WorkOrderCardList from './WorkOrderCardList';

export type WorkOrderActionNames = 'changeStatusDone' | 'onDelete';

export type WorkOrderActionPayload = { id: number; status: WorkOrderStatus };

export type WorkOrderActionsType = {
  [key in WorkOrderActionNames]?: (payload: WorkOrderActionPayload) => void;
};

export type WorkOrderCardListTabsProps = {
  onWorkOrderClicked: (id: number) => void;
  targetWorkOrderId?: number;
  workOrderActions: WorkOrderActionsType;
  hidden: boolean;
};

export const PAGE_LIMIT = 50;

const WorkOrderCardListTabs: FC<WorkOrderCardListTabsProps> = (
  props: WorkOrderCardListTabsProps
) => {
  const {
    onWorkOrderClicked,
    targetWorkOrderId,
    workOrderActions: { changeStatusDone, onDelete },
    hidden,
  } = props;
  const {
    loadMoreDoneWorkOrders,
    doneWorkOrders = [],
    paramsHandler,
    notDoneWorkOrderTypes,
    notDoneWorkOrdersMap,
    isLoadingWorkOrders,
    setIsLoadingWorkOrders,
    doneWorkOrdersTotalCount,
  } = useWorkOrderPageContext();
  const { setUrlHash } = useListAndDetail();
  const { getAccordionLabel } = useListAccordions();

  const { t, t_ns } = useTranslation(TASK);
  const { getStatusLabel } = useWorkOrderStatus();

  const { isDone, setIsDone, page } = paramsHandler;
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleTabsChange = (index: number) => {
    setIsLoadingWorkOrders(true);
    setTabIndex(index);
    if (index === 0) {
      setIsDone(false);
    } else {
      setIsDone(true);
    }

    setUrlHash('');
  };

  const handleLoadMore = async () => {
    setLoading(true);
    await loadMoreDoneWorkOrders();
    setLoading(false);
  };

  useEffect(() => {
    setTabIndex(isDone ? 1 : 0);
  }, [isDone]);

  const element = useRef<HTMLDivElement>(null);
  const [isFirstRendering, setIsFirstRendering] = useState(true);

  // biome-ignore lint/correctness/useExhaustiveDependencies: why is this element.current?.scrollHeight necessary?
  useEffect(() => {
    if (element.current?.scrollHeight && (!hidden || isFirstRendering)) {
      setIsFirstRendering(false);
      const rect = element.current.getBoundingClientRect();
      const isVisible =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth);
      // 要素がビューポート内に完全に見えていない場合のみスクロール
      if (!isVisible) {
        element.current.scrollIntoView({
          behavior: 'instant',
          block: 'center',
        });
      }
    }
    // hiddenが切り替わるたびに、scrollの位置を変える
    // scrollHeightがないと、ブラウザでリロード時にスクロールされない
  }, [hidden, isFirstRendering, element.current?.scrollHeight]);

  return (
    <Box
      minW={{ base: 'full', md: '350px' }}
      maxW={{ base: 'full', md: '350px' }}
      overflowY='scroll'
      hidden={hidden}
    >
      <Tabs w='full' index={tabIndex} onChange={handleTabsChange}>
        <TabList>
          <Tab w='full'> {t('status.todo')}</Tab>
          <Tab w='full'>{getStatusLabel('done')}</Tab>
        </TabList>
        {isLoadingWorkOrders ? (
          <SimpleSpinner />
        ) : (
          <TabPanels p={0}>
            <TabPanel p={0}>
              {notDoneWorkOrdersMap.size > 0 ? (
                notDoneWorkOrderTypes.map((key) => {
                  const workOrders = notDoneWorkOrdersMap.get(key);
                  const title = getAccordionLabel(key);

                  return (
                    workOrders &&
                    workOrders.length > 0 && (
                      <AccordionListWrapper
                        key={key}
                        title={title}
                        accordionIndexKey={`task-${key}-accordion-index`}
                      >
                        <WorkOrderCardList
                          targetWorkOrderId={targetWorkOrderId}
                          workOrders={workOrders}
                          onWorkOrderClicked={onWorkOrderClicked}
                          workOrderActions={{ changeStatusDone, onDelete }}
                          ref={element}
                        />
                      </AccordionListWrapper>
                    )
                  );
                })
              ) : (
                <ListEmptyText text={t_ns('no-active-tasks')} />
              )}
            </TabPanel>
            <TabPanel p={0}>
              {doneWorkOrders.length > 0 ? (
                <AccordionListWrapper
                  title={getStatusLabel('done')}
                  accordionIndexKey='task-done-accordion-index-key'
                >
                  <WorkOrderCardList
                    targetWorkOrderId={targetWorkOrderId}
                    workOrders={doneWorkOrders}
                    onWorkOrderClicked={onWorkOrderClicked}
                    page={page}
                    handleLoadMore={handleLoadMore}
                    loading={loading}
                    workOrderActions={{ onDelete }}
                    workOrdersTotalLength={doneWorkOrdersTotalCount}
                    pageLimit={PAGE_LIMIT}
                    ref={element}
                  />
                </AccordionListWrapper>
              ) : (
                <ListEmptyText text={t_ns('no-completed-tasks')} />
              )}
            </TabPanel>
          </TabPanels>
        )}
      </Tabs>
    </Box>
  );
};

export default WorkOrderCardListTabs;
