import { WorkOrderFormDefaultValuesType } from '@/context/CreateWorkOrderContext';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
} from '@chakra-ui/react';
import { MdFileDownload, MdMoreVert, MdOutlineInsertDriveFile } from 'react-icons/md';
import WorkOrderFilter from './WorkOrderFilter';

import DeviceAdaptiveActionButton from '@/common/components/DeviceAdaptiveActionButton';
import DropDownMenuWrapper from '@/common/components/DropDownMenuWrapper';
import Link from '@/common/components/Link';
import PageTitle from '@/common/components/PageTitle';
import PopoverMessageWrapper from '@/common/components/PopoverMessageWrapper';
import { useApplicationContext } from '@/context/ApplicationContext';
import { useTemplateContext } from '@/context/TemplateContext';
import QrScannerModal from '@/modules/assets/components/QrScannerModal';
import VoiceControlModal from '@/modules/assets/components/VoiceControlModal';
import { IWorkOrderTemplateBase } from '@/modules/workOrders/types/workOrderTemplate';
import { TASK } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';

type WorkOrderToolbarProps = {
  accessAiVoice: boolean;
  showMobileDetail: boolean;
  workOrderTemplate: IWorkOrderTemplateBase;
  workOrderCreateButtonClicked: (assetId?: number) => void;
  onTemplateSettingsClick?: () => void;
  onAiVoiceSubmit: (preWorkOrderData: WorkOrderFormDefaultValuesType) => void;
};

export default function WorkOrderToolbar(props: WorkOrderToolbarProps) {
  const { isDesktop } = useScreenInfos();

  const {
    accessAiVoice,
    showMobileDetail,
    workOrderCreateButtonClicked,
    onTemplateSettingsClick,
    workOrderTemplate,
    onAiVoiceSubmit,
  } = props;

  const { t, t_ns } = useTranslation(TASK);
  const { isAdmin } = useApplicationContext();
  const { defaultWorkOrderTemplate, workOrderTemplates, fetchAndSetDefaultWorkOrderTemplate } =
    useTemplateContext();

  const handleTemplateSelect = async (id: number) => {
    await fetchAndSetDefaultWorkOrderTemplate(id);

    workOrderCreateButtonClicked();
  };

  const handleCustomizeTemplateSelect = async (id: number) => {
    await fetchAndSetDefaultWorkOrderTemplate(id);

    onTemplateSettingsClick && onTemplateSettingsClick();
  };

  const workOrderTemplatesForMenuItems = workOrderTemplates.map((template) => ({
    id: template.id,
    name: template.name,
    Icon: MdOutlineInsertDriveFile,
  }));

  const hasMultipleTemplates = workOrderTemplates.length > 1;

  return (
    <>
      <PageTitle
        title={t('pages.task')}
        titleSize='md'
        borderBottom='0px'
        hidden={showMobileDetail}
      >
        <HStack spacing='5px'>
          <DropDownMenuWrapper
            menuItems={workOrderTemplatesForMenuItems}
            activeMenuItemId={defaultWorkOrderTemplate?.id}
            onMenuItemClick={handleTemplateSelect}
            variant='extendedFAB'
          >
            <DeviceAdaptiveActionButton
              as={hasMultipleTemplates ? Box : undefined} // to avoid validateDOMNesting warning
              text={t_ns('new-task')}
              onClick={workOrderCreateButtonClicked}
              mobileProps={hasMultipleTemplates ? { position: 'static' } : {}}
            />
          </DropDownMenuWrapper>
          <QrScannerModal workOrderCreateButtonClicked={workOrderCreateButtonClicked} />

          {accessAiVoice && (
            <VoiceControlModal onAiVoiceSubmit={onAiVoiceSubmit} template={workOrderTemplate} />
          )}
          {isDesktop && (
            <Menu>
              <MenuButton
                as={IconButton}
                size='sm'
                mr={2}
                colorScheme='gray'
                variant='ghost'
                fontSize={20}
                aria-label='Asset More Action'
                icon={<MdMoreVert />}
              />
              <MenuList zIndex={101}>
                <PopoverMessageWrapper
                  message={t('warning.no-permission.download')}
                  isDisabled={!isAdmin}
                  placement='bottom-end'
                >
                  <Link to='/dashboard/#export'>
                    <MenuItem
                      fontSize='sm'
                      fontWeight='normal'
                      icon={<MdFileDownload />}
                      isDisabled={!isAdmin}
                    >
                      {t('actions.batch-download')}
                    </MenuItem>
                  </Link>
                </PopoverMessageWrapper>
                <PopoverMessageWrapper
                  isDisabled={!isAdmin}
                  message={t('warning.no-permission.edit')}
                  placement='bottom-end'
                >
                  <DropDownMenuWrapper
                    menuItems={workOrderTemplatesForMenuItems}
                    activeMenuItemId={defaultWorkOrderTemplate?.id}
                    onMenuItemClick={handleCustomizeTemplateSelect}
                    gutter={20}
                    width='full'
                    _hover={{ bgColor: 'gray.100' }}
                    showArrowIcon
                  >
                    <MenuItem
                      as={Box}
                      isDisabled={!isAdmin}
                      fontSize='sm'
                      fontWeight='normal'
                      onClick={onTemplateSettingsClick}
                      bgColor='transparent'
                    >
                      {t('actions.customize')}
                    </MenuItem>
                  </DropDownMenuWrapper>
                </PopoverMessageWrapper>
              </MenuList>
            </Menu>
          )}
        </HStack>
      </PageTitle>
      <Flex
        borderBottom='1px'
        borderColor='neutral.300'
        bg='chakra-body-bg'
        hidden={showMobileDetail}
      >
        <WorkOrderFilter />
        <Spacer />
      </Flex>
    </>
  );
}
