import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { FC, useCallback, useEffect, useState } from 'react';
import { FaArrowDown, FaArrowUp, FaExternalLinkAlt, FaLink, FaRegFilePdf } from 'react-icons/fa';
import { MdDelete, MdMode, MdMoreVert, MdOutlineCopyAll } from 'react-icons/md';

import BackIconButton from '@/common/components/BackIconButton';
import DetailDisplayFileItem from '@/common/components/DetailDisplayFileItem';
import DetailDisplayItem from '@/common/components/DetailDisplayItem';
import DisplayDownTime from '@/common/components/DisplayDownTime';
import Link from '@/common/components/Link';
import PageTitle from '@/common/components/PageTitle';
import PopoverMessageWrapper from '@/common/components/PopoverMessageWrapper';
import { valueWithCategoryName } from '@/common/components/customFields';
import { Maybe, StringMap } from '@/common/types';
import { useApplicationContext } from '@/context/ApplicationContext';
import { useConfirmModal } from '@/context/ConfirmModalContext';
import { type WorkOrderPriority, type WorkOrderStatus } from '@/graphql/types';
import { formatAssetName } from '@/modules/assets/utils';
import { ICheckList, IUpdateCheckListInput } from '@/modules/checkList';
import { formatProductName } from '@/modules/products';
import {
  useWorkOrderCommentEventSubscription,
  useWorkOrderCommentsQuery,
} from '@/modules/workOrders/graphql/workOrderComments.generated';
import {
  IWorkOrder,
  IWorkOrderCustomFieldDateValueInput,
  IWorkOrderCustomFieldDatetimeValueInput,
  IWorkOrderCustomFieldFloatValueInput,
  IWorkOrderCustomFieldIntValueInput,
  IWorkOrderCustomFieldSelectValueInput,
  IWorkOrderCustomFieldTextValueInput,
  IWorkOrderCustomFieldUserValueInput,
  IWorkOrderPart,
  IWorkOrderStoppage,
} from '@/modules/workOrders/types/workOrder';
import { IWorkOrderComment } from '@/modules/workOrders/types/workOrderComment';
import { IWorkOrderCustomField } from '@/modules/workOrders/types/workOrderCustomField';
import { IWorkOrderTemplateBase } from '@/modules/workOrders/types/workOrderTemplate';
import { useToast } from '@/utils/atoms/toast';
import { WorkOrderCSVType } from '@/utils/csv/useWorkOrderCSV';
import {
  CheckListFormValueType,
  createCheckListFormValue,
  generateCheckListInitFormValue,
} from '@/utils/customFields/checkListCustomFields';
import {
  formatDateToMDHHmm_or_YYYYMDHHmm,
  formatDateToMD_or_YYYYMD,
  formatDateToYYYYMDHHmm,
  formatDateToYYYYMMDDHHmmForInput,
} from '@/utils/date/date';
import { FileContentType, FileIdAndUrl } from '@/utils/file/type';
import { TASK } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { TbSend } from 'react-icons/tb';
import WorkOrderPriorityLabel from './WorkOrderPriorityLabel';
import WorkOrderStartEndDatePicker from './WorkOrderStartEndDatePicker';
import WorkOrderStatusRadio from './WorkOrderStatusRadio';
import { WorkOrderStoppageReasonPicker } from './WorkOrderStoppageReasonPicker';
import WorkOrderCheckListField from './checkList/WorkOrderCheckListField';
import { CheckListFieldCardType } from './checkList/WorkOrderCheckListFieldCard';
import WorkOrderComments from './comments/WorkOrderComments';
import { CustomFieldFileValueType } from './customFields/CustomFieldFileValueType';
import CustomFieldItem from './customFields/CustomFieldItem';
import WorkOrderPartField, { WorkOrderPartCardType } from './parts/WorkOrderPartField';

import { SuspenseWithSpinner } from '@/common/components/SuspenseWithSpinner';
import { lazy } from 'react';
import React from 'react';
const WorkOrderDetailPDFModal = lazy(() => import('./WorkOrderDetailPDFModal'));
const WorkOrderCheckListCustomFieldList = lazy(
  () => import('./checkList/WorkOrderCheckListCustomFieldList')
);
const WorkOrderCheckListFormModal = lazy(() => import('./checkList/WorkOrderCheckListFormModal'));

export type WorkOrderUpdateDataType = {
  status?: WorkOrderStatus;
  priority?: WorkOrderPriority;
  stoppage: IWorkOrderStoppage;
  parts: IWorkOrderPart[];
  stoppageReasonId?: number | null;
  customFieldTextValues: IWorkOrderCustomFieldTextValueInput[];
  customFieldIntValues: IWorkOrderCustomFieldIntValueInput[];
  customFieldFloatValues: IWorkOrderCustomFieldFloatValueInput[];
  customFieldSelectValues: IWorkOrderCustomFieldSelectValueInput[];
  customFieldDateValues: IWorkOrderCustomFieldDateValueInput[];
  customFieldDatetimeValues: IWorkOrderCustomFieldDatetimeValueInput[];
  customFieldUserValues: IWorkOrderCustomFieldUserValueInput[];
  checkLists: IUpdateCheckListInput[];
};

export type WorkOrderDetailProps = {
  workOrder: IWorkOrder;
  template: IWorkOrderTemplateBase;
  displayUpdateWorkOrderForm: () => void;
  deleteWorkOrder: () => void;
  onAddCustomFieldFileValues: (fileValues: CustomFieldFileValueType[]) => void;
  onRemoveCustomFieldFileValue: (fileId: string, customFieldId: number) => void;
  onUpdateCustomFieldFileValue: (
    fileId: string,
    params: {
      fileId: string;
      contentType: string;
      name: string;
      src: string;
    }
  ) => Promise<void>;
  updateWorkOrder: (workOrder: WorkOrderUpdateDataType) => void;
  getFileUploadUrls: (filesContentTypes: FileContentType[]) => Promise<FileIdAndUrl[]>;
  onClickNotificationButton: () => void;
  onClickCopyWorkOrderButton: (workOrderId: number) => Promise<void>;
  customFieldFileValues: CustomFieldFileValueType[];
  onCreateWorkOrderTextSuggest: (
    label: string,
    text: string,
    workOrder: WorkOrderCSVType
  ) => Promise<string | false>;
  onUpdatePart(partId: number, quantity: number): void;
  updateCheckList: (checkList: IUpdateCheckListInput) => void;
  moveWorkOrder: (workOrderId: number, status: WorkOrderStatus, offset: number) => void;
  onBackButtonClicked: () => void;
};

type FormDataType = {
  stoppageStartAt: string | null;
  stoppageEndAt: string | null;
  stoppageReasonId: number | null;
};

export const INITIAL_COMMENT_LIMIT = 4;

const WorkOrderDetail: FC<WorkOrderDetailProps> = (props: WorkOrderDetailProps) => {
  const { toast } = useToast();
  const { confirm } = useConfirmModal();
  const { me, isAdmin, companySetting } = useApplicationContext();

  const {
    isOpen: isPartRegisterModalOpen,
    onOpen: onPartRegisterModalOpen,
    onClose: onPartRegisterModalClose,
  } = useDisclosure();

  const {
    workOrder: {
      id,
      title,
      description,
      asset,
      product,
      status,
      priority,
      customFieldTextValues = [],
      customFieldIntValues = [],
      customFieldFloatValues = [],
      customFieldSelectValues = [],
      customFieldDatetimeValues = [],
      customFieldDateValues = [],
      customFieldUserValues = [],
      stoppage,
      createdBy,
      createdAt,
      updatedBy,
      updatedAt,
      checkLists,
      dueDate,
      stoppageReason,
      assignees = [],
      parts: workOrderParts,
      commentCount,
      templateId,
      isScheduled,
      requests = [],
      groups = [],
    },
    displayUpdateWorkOrderForm,
    deleteWorkOrder,
    onAddCustomFieldFileValues,
    onRemoveCustomFieldFileValue,
    onUpdateCustomFieldFileValue,
    onUpdatePart,
    updateWorkOrder,
    getFileUploadUrls,
    onClickNotificationButton,
    onCreateWorkOrderTextSuggest,
    onClickCopyWorkOrderButton,
    template,
    customFieldFileValues,
    updateCheckList,
    moveWorkOrder,
    onBackButtonClicked,
  } = props;

  const { customFields, fieldOrders } = template;

  const { t, t_errors, t_toasts, t_ns } = useTranslation(TASK);
  const formattedCreatedAt = formatDateToMDHHmm_or_YYYYMDHHmm(createdAt);
  const formattedUpdatedAt = formatDateToMDHHmm_or_YYYYMDHHmm(updatedAt);

  const {
    isOpen: isCheckListFormOpen,
    onOpen: onCheckListFormOpen,
    onClose: onCheckListFormClose,
  } = useDisclosure();
  const { isMobile } = useScreenInfos();

  const { data: dataWorkOrderComments, fetchMore: fetchMoreComments } = useWorkOrderCommentsQuery({
    variables: { workOrderId: id, limit: INITIAL_COMMENT_LIMIT },
  });

  const { data: commentEventData } = useWorkOrderCommentEventSubscription({
    variables: { workOrderId: Number(id) },
  });

  const [workOrderComments, setWorkOrderComments] = useState<IWorkOrderComment[]>([]);
  const [userAvatarBgColorMap, setUserAvatarBgColorMap] = useState<StringMap>({});

  const [customFieldLocalTextValues, setCustomFieldLocalTextValues] = useState<
    IWorkOrderCustomFieldTextValueInput[]
  >(
    customFieldTextValues.map((entry) => ({
      customFieldId: entry.customFieldId,
      value: entry.value,
    }))
  );
  const [customFieldLocalIntValues, setCustomFieldLocalIntValues] = useState<
    IWorkOrderCustomFieldIntValueInput[]
  >(
    customFieldIntValues.map((entry) => ({
      customFieldId: entry.customFieldId,
      value: entry.value,
    }))
  );
  const [customFieldLocalFloatValues, setCustomFieldLocalFloatValues] = useState<
    IWorkOrderCustomFieldFloatValueInput[]
  >(
    customFieldFloatValues.map((entry) => ({
      customFieldId: entry.customFieldId,
      value: entry.value,
    }))
  );
  const [customFieldLocalSelectValues, setCustomFieldLocalSelectValues] = useState<
    IWorkOrderCustomFieldSelectValueInput[]
  >(
    customFieldSelectValues.map((entry) => ({
      customFieldId: entry.customFieldId,
      optionId: entry.optionId,
    }))
  );
  const [customFieldLocalDateValues, setCustomFieldLocalDateValues] = useState<
    IWorkOrderCustomFieldDateValueInput[]
  >(
    customFieldDateValues.map((entry) => ({
      customFieldId: entry.customFieldId,
      value: entry.value,
    }))
  );
  const [customFieldLocalDatetimeValues, setCustomFieldLocalDatetimeValues] = useState<
    IWorkOrderCustomFieldDatetimeValueInput[]
  >(
    customFieldDatetimeValues.map((entry) => ({
      customFieldId: entry.customFieldId,
      value: entry.value,
    }))
  );

  const [customFieldLocalUserValues, setCustomFieldLocalUserValues] = useState<
    IWorkOrderCustomFieldUserValueInput[]
  >(
    customFieldUserValues.map((entry) => ({
      customFieldId: entry.customFieldId,
      userId: entry.user.id,
    }))
  );

  const [targetCheckList, setTargetCheckList] = useState<Maybe<ICheckList>>(
    checkLists.length === 1 ? checkLists[0] : undefined
  );
  const [localWorkOrderParts, setLocalWorkOrderParts] = useState<WorkOrderPartCardType[]>(
    workOrderParts.map((entry) => {
      if (!entry.part) throw new Error(t_errors('impossible'));
      return {
        partId: entry.part.id,
        name: entry.part.name,
        stock: entry.part.stock,
        quantity: entry.quantity,
        unit: entry.part.unit || undefined,
        cost: entry.part.cost || undefined,
        currencyCode: entry.part.currencyCode || undefined,
      };
    })
  );

  const [localCheckLists, setLocalCheckLists] = useState<CheckListFieldCardType[]>(
    checkLists.map((entry) => {
      if (!entry.id) throw new Error(t_errors('impossible'));
      return {
        id: entry.id,
        templateId: entry.template.id,
        name: entry.template.name,
      };
    })
  );

  const methods = useForm<FormDataType>({
    defaultValues: {
      stoppageStartAt: formatDateToYYYYMMDDHHmmForInput(stoppage?.startAt) ?? null,
      stoppageEndAt: formatDateToYYYYMMDDHHmmForInput(stoppage?.endAt) ?? null,
      stoppageReasonId: stoppageReason?.id ?? null,
    },
  });

  const { getValues } = methods;

  let displayCustomFields: IWorkOrderCustomField[] = [];
  if (customFields) {
    displayCustomFields = customFields.filter((customField) => {
      const { inputTimings } = customField;
      if (inputTimings.length === 0) return false;
      if (inputTimings.includes(status)) return false;

      return true;
    });
  }

  let inputCustomFields: IWorkOrderCustomField[] = [];

  if (customFields) {
    inputCustomFields = customFields.filter((customField) => {
      const { inputTimings } = customField;
      if (inputTimings.length === 0) return true;
      if (inputTimings.includes(status)) return true;

      return false;
    });
  }

  useEffect(() => {
    if (!dataWorkOrderComments) return;
    // 最新のものが下に表示されるため、reverseしている
    setWorkOrderComments(
      [...(dataWorkOrderComments.workOrderComments as IWorkOrderComment[])].reverse()
    );
  }, [dataWorkOrderComments]);

  useEffect(() => {
    if (!commentEventData) return;
    const { workOrderCommentEvent } = commentEventData;
    const { eventType, workOrderComment, commentId } = workOrderCommentEvent;
    switch (eventType) {
      case 'added':
        if (!workOrderComment) throw new Error('workOrderComment is not defined in added event');
        setWorkOrderComments((prevComments) => [...prevComments, workOrderComment]);
        break;
      case 'updated':
        if (!workOrderComment) throw new Error('workOrderComment is not defined in updated event');
        setWorkOrderComments((prevComments) =>
          prevComments.map((comment) =>
            comment.id === workOrderComment.id ? workOrderComment : comment
          )
        );
        break;
      case 'removed':
        setWorkOrderComments((prevComments) =>
          prevComments.filter((comment) => comment.id !== commentId)
        );
        break;
    }
  }, [commentEventData]);

  const isDone = status === 'done';
  const hasInputCustomFields = inputCustomFields.length > 0;
  const isWorkOrderPartFieldEdit = !isDone;
  const isUserWorkOrderOwner = createdBy?.id === me?.id;
  const hasDeletePermission = isAdmin || (!isDone && isUserWorkOrderOwner);

  const handleFetchMoreComments = useCallback(async () => {
    try {
      const { data, error } = await fetchMoreComments({
        variables: {
          workOrderId: id,
          offset: 4,
          limit: undefined,
        },
      });

      if (error) throw error;
      if (!data) throw new Error('Data Error');

      setWorkOrderComments((prevComments) => {
        // 表示中のコメントと重複しないようにする
        const prevCommentsIds = prevComments.map((comment) => comment.id);
        const uniqueNewComments = data.workOrderComments
          .filter((comment) => !prevCommentsIds.includes(comment.id))
          .reverse() as IWorkOrderComment[];
        return [...uniqueNewComments, ...prevComments];
      });
    } catch (error) {
      console.error(error);
      toast({
        title: t_toasts('failed.get-comments'),
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  }, [fetchMoreComments, toast, id, t_toasts]);

  const handleSetUserAvatarBgColor = useCallback((userId: string, color: string) => {
    setUserAvatarBgColorMap((prev) => ({ ...prev, [userId]: color }));
  }, []);

  const onClickLinkCreateButton = async () => {
    const text = `${formatDateToYYYYMDHHmm(createdAt)}\n${formatAssetName(asset)}\n${
      location.origin
    }#${id}`;
    try {
      await navigator.clipboard.writeText(text);
      toast({
        title: t_toasts('success.copy-link'),
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (_e) {
      toast({
        title: t_toasts('failed.copy-link'),
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const shouldConfirmPartsRegistration = (status: WorkOrderStatus) => {
    return (
      status === 'done' &&
      fieldOrders.find((fieldOrder) => fieldOrder.type === 'part') &&
      localWorkOrderParts.length === 0
    );
  };

  const getStoppageTimes = (): IWorkOrderStoppage => {
    const stopStartAt = getValues('stoppageStartAt');
    const stopEndAt = getValues('stoppageEndAt');

    const startAt = stopStartAt ? new Date(stopStartAt as string) : undefined;
    const endAt = stopEndAt ? new Date(stopEndAt as string) : undefined;

    return { startAt, endAt };
  };

  const onChangeWorkOrder = async (nextStatus: WorkOrderStatus = status) => {
    if (shouldConfirmPartsRegistration(nextStatus)) {
      const result = await confirm(
        t('confirmation.parts.confirm-add-part-message'),
        undefined,
        t('actions.mark-as-done'),
        {
          colorScheme: 'primary',
        },
        t('actions.register-part')
      );
      if (!result) {
        onPartRegisterModalOpen();
        return;
      }
    }

    if (status === 'done' && nextStatus !== 'done') {
      const result = await confirm(
        t('confirmation.task.revert-done-task'),
        undefined,
        t('actions.mark-as-incomplete'),
        {
          colorScheme: 'primary',
        },
        t('actions.cancel')
      );
      if (!result) {
        return;
      }
    }

    const checkLists = [];
    if (targetCheckList) {
      const customFieldStampValues = checkListFormValue.customFieldStampValues.map(
        (customFieldStampValue) => {
          return {
            customFieldId: customFieldStampValue.customFieldId,
            stampedById: customFieldStampValue.stampedBy.id,
            stampedAt: customFieldStampValue.stampedAt,
          };
        }
      );
      const customFieldAttachments = checkListFormValue.customFieldAttachments.map(
        (customFieldAttachment) => {
          return {
            customFieldId: customFieldAttachment.customFieldId,
            contentType: customFieldAttachment.contentType,
            name: customFieldAttachment.name,
            fileId: customFieldAttachment.fileId,
          };
        }
      );
      checkLists.push({
        id: targetCheckList.id,
        ...checkListFormValue,
        customFieldAttachments,
        customFieldStampValues,
      });
    }

    const { startAt, endAt } = getStoppageTimes();

    updateWorkOrder({
      status: nextStatus,
      stoppage: { startAt, endAt },
      stoppageReasonId: getValues('stoppageReasonId') ?? null,
      customFieldTextValues: customFieldLocalTextValues,
      customFieldIntValues: customFieldLocalIntValues,
      customFieldFloatValues: customFieldLocalFloatValues,
      customFieldSelectValues: customFieldLocalSelectValues,
      customFieldDateValues: customFieldLocalDateValues,
      customFieldDatetimeValues: customFieldLocalDatetimeValues,
      customFieldUserValues: customFieldLocalUserValues,
      parts: localWorkOrderParts.map((part) => {
        return { partId: part.partId, quantity: part.quantity };
      }),
      checkLists,
    });
  };

  const onChangeCheckLists = useCallback((checkLists: CheckListFieldCardType[]) => {
    setLocalCheckLists(checkLists);
  }, []);

  const onChangeWorkOrderPart = useCallback((parts: WorkOrderPartCardType[]) => {
    setLocalWorkOrderParts(parts);
  }, []);

  const onCreateTextSuggest = async (label: string, text: string) => {
    const { startAt, endAt } = getStoppageTimes();

    const workOrder: WorkOrderCSVType = {
      title,
      asset,
      status,
      createdAt,
      stoppage: { startAt, endAt },
      customFieldTextValues: customFieldLocalTextValues,
      customFieldIntValues: customFieldLocalIntValues,
      customFieldFloatValues: customFieldLocalFloatValues,
      customFieldSelectValues: customFieldSelectValues,
      customFieldDatetimeValues: customFieldLocalDatetimeValues,
      customFieldDateValues: customFieldLocalDateValues,
      customFieldUserValues: customFieldUserValues,
    };
    return await onCreateWorkOrderTextSuggest(label, text, workOrder);
  };

  const [showPDFModal, setPDFModalState] = useState<boolean>(false);
  const onGeneratePDF = useCallback(() => {
    setPDFModalState(true);
  }, []);
  const onPDFModalClose = useCallback(() => {
    setPDFModalState(false);
  }, []);

  const onCheckListClicked = useCallback(
    (checkListField: CheckListFieldCardType) => {
      const checkList = checkLists.find((checkList) => checkList.id === checkListField.id);
      if (checkList === undefined) throw new Error(t_errors('impossible'));
      setTargetCheckList(checkList);
      setCheckListFormValue(createCheckListFormValue(checkList));
      onCheckListFormOpen();
    },
    [checkLists, onCheckListFormOpen, t_errors]
  );

  const [checkListFormValue, setCheckListFormValue] = useState<CheckListFormValueType>(
    targetCheckList ? createCheckListFormValue(targetCheckList) : generateCheckListInitFormValue()
  );

  const onCheckListUpdated = useCallback(() => {
    if (!targetCheckList) throw new Error(t_errors('impossible'));
    const customFieldStampValues = checkListFormValue.customFieldStampValues.map(
      (customFieldStampValue) => {
        return {
          customFieldId: customFieldStampValue.customFieldId,
          stampedById: customFieldStampValue.stampedBy.id,
          stampedAt: customFieldStampValue.stampedAt,
        };
      }
    );
    const customFieldAttachments = checkListFormValue.customFieldAttachments.map(
      (customFieldAttachment) => {
        return {
          customFieldId: customFieldAttachment.customFieldId,
          contentType: customFieldAttachment.contentType,
          name: customFieldAttachment.name,
          fileId: customFieldAttachment.fileId,
        };
      }
    );
    const checkList: IUpdateCheckListInput = {
      id: targetCheckList.id,
      ...checkListFormValue,
      customFieldAttachments,
      customFieldStampValues,
    };
    updateCheckList(checkList);
    setTargetCheckList(undefined);
    onCheckListFormClose();
  }, [targetCheckList, checkListFormValue, updateCheckList, onCheckListFormClose, t_errors]);

  const onChangeCheckListValue = (checkListFormValue: CheckListFormValueType) => {
    setCheckListFormValue(checkListFormValue);
  };

  const generateCustomFieldItem = (customFieldId: number, type: 'view' | 'input') => {
    const displayCustomField = displayCustomFields.find((entry) => entry.id === customFieldId);
    const inputCustomField = inputCustomFields.find((entry) => entry.id === customFieldId);
    const customFieldFileValue = customFieldFileValues.filter(
      (entry) => entry.customFieldId === customFieldId
    );

    let defaultValue = '';
    let remark;
    if (displayCustomField) {
      switch (displayCustomField.type) {
        case 'file':
          break;

        case 'text':
          const customFieldTextValue = customFieldTextValues.find(
            (value) => value.customFieldId === displayCustomField.id
          );
          defaultValue = customFieldTextValue ? customFieldTextValue.value : '';
          break;
        case 'int':
          const customFieldIntValue = customFieldIntValues.find(
            (value) => value.customFieldId === displayCustomField.id
          );
          defaultValue = customFieldIntValue ? customFieldIntValue.value.toString() : '';
          break;
        case 'float':
          const customFieldFloatValue = customFieldFloatValues.find(
            (value) => value.customFieldId === displayCustomField.id
          );
          defaultValue = customFieldFloatValue ? customFieldFloatValue.value.toString() : '';
          break;
        case 'select':
          const customFieldSelectLocalValues = customFieldSelectValues.filter(
            (value) => value.customFieldId === displayCustomField.id
          );
          defaultValue = customFieldSelectLocalValues
            .map((entry) => valueWithCategoryName(entry))
            .join(',');
          remark = customFieldSelectLocalValues
            .filter((entry) => entry.remark)
            .map((entry) => entry.remark)
            .join(',');
          break;
        case 'datetime':
          const customFieldDatetimeValue = customFieldDatetimeValues.find(
            (value) => value.customFieldId === displayCustomField.id
          );
          defaultValue = customFieldDatetimeValue
            ? formatDateToMDHHmm_or_YYYYMDHHmm(customFieldDatetimeValue.value)
            : '';
          break;
        case 'date':
          const customFieldDateValue = customFieldDateValues.find(
            (value) => value.customFieldId === displayCustomField.id
          );
          defaultValue = customFieldDateValue
            ? formatDateToMD_or_YYYYMD(customFieldDateValue.value)
            : '';
        case 'user':
          const customFieldUserLocalValues = customFieldUserValues.filter(
            (value) => value.customFieldId === displayCustomField.id
          );
          defaultValue = customFieldUserLocalValues
            ? customFieldUserLocalValues.map((entry) => entry.user.name).join(',')
            : '';
          break;
        default:
          throw new Error('impossible');
      }
    }

    if (type === 'view' && displayCustomField) {
      return displayCustomField.type === 'file' ? (
        <>
          {customFieldFileValue.length > 0 && (
            <DetailDisplayFileItem
              key={displayCustomField.id}
              label={displayCustomField.label}
              files={customFieldFileValue.filter(
                (entry) => entry.customFieldId === displayCustomField.id
              )}
            />
          )}
        </>
      ) : (
        <>
          {defaultValue && (
            <DetailDisplayItem
              key={displayCustomField.id}
              label={displayCustomField.label}
              value={defaultValue}
              remark={remark}
              vertical={displayCustomField.type === 'text' || remark ? true : false}
            />
          )}
        </>
      );
    }

    if (type === 'input' && inputCustomField) {
      return (
        <CustomFieldItem
          assetId={asset ? asset.id : undefined}
          onCreateTextSuggest={onCreateTextSuggest}
          suggestible={accessSuggest}
          customField={inputCustomField}
          customFieldTextValues={customFieldLocalTextValues}
          customFieldIntValues={customFieldLocalIntValues}
          customFieldFloatValues={customFieldLocalFloatValues}
          customFieldSelectValues={customFieldLocalSelectValues}
          customFieldDateValues={customFieldLocalDateValues}
          customFieldDatetimeValues={customFieldLocalDatetimeValues}
          customFieldUserValues={customFieldLocalUserValues}
          customFieldFileValues={customFieldFileValues}
          changeCustomFieldTextValues={setCustomFieldLocalTextValues}
          changeCustomFieldIntValues={setCustomFieldLocalIntValues}
          changeCustomFieldFloatValues={setCustomFieldLocalFloatValues}
          changeCustomFieldSelectValues={setCustomFieldLocalSelectValues}
          changeCustomFieldDatetimeValues={setCustomFieldLocalDatetimeValues}
          changeCustomFieldDateValues={setCustomFieldLocalDateValues}
          changeCustomFieldUserValues={setCustomFieldLocalUserValues}
          onRemoveCustomFieldFileValue={onRemoveCustomFieldFileValue}
          onAddCustomFieldFileValues={onAddCustomFieldFileValues}
          getFileUploadUrls={getFileUploadUrls}
          onUpdateCustomFieldFileValue={onUpdateCustomFieldFileValue}
        />
      );
    }

    return null;
  };

  const onWorkOrderNextButtonClicked = () => {
    moveWorkOrder(id, status, 1);
  };

  const onWorkOrderPreviousButtonClicked = () => {
    moveWorkOrder(id, status, -1);
  };

  if (!companySetting) return null;
  const { accessGroup, accessSuggest, accessProduct } = companySetting;

  return (
    <Box bg='neutral.50'>
      <PageTitle
        title={t_ns('task-detail')}
        titleSize='sm'
        p={{ base: 0, md: '2' }}
        mb={0}
        position='sticky'
        top={0}
        zIndex={9}
        leftChild={isMobile && <BackIconButton onBackButtonClicked={onBackButtonClicked} />}
      >
        <Button
          variant='solid'
          size='sm'
          colorScheme='primary'
          aria-label='WorkOrder Notification'
          onClick={onClickNotificationButton}
          leftIcon={<TbSend />}
        >
          {t('notify')}
        </Button>
        <Box>
          <Menu>
            <MenuButton
              as={IconButton}
              size='sm'
              colorScheme='gray'
              variant='ghost'
              fontSize={20}
              aria-label='WorkOrder Menu'
              icon={<MdMoreVert />}
            />
            <MenuList zIndex={2}>
              <MenuItem
                onClick={() => displayUpdateWorkOrderForm()}
                fontSize='sm'
                fontWeight='normal'
                aria-label='Update WorkOrder'
                icon={<MdMode />}
              >
                {t('actions.edit')}
              </MenuItem>
              <MenuItem
                onClick={() => onClickCopyWorkOrderButton(id)}
                fontSize='sm'
                fontWeight='normal'
                aria-label='Duplicate WorkOrder'
                icon={<MdOutlineCopyAll />}
              >
                {t('actions.duplicate')}
              </MenuItem>

              <MenuItem
                onClick={() => onGeneratePDF()}
                fontSize='sm'
                fontWeight='normal'
                icon={<FaRegFilePdf />}
              >
                {t('PDF-export')}
              </MenuItem>
              <MenuItem
                onClick={() => onClickLinkCreateButton()}
                fontSize='sm'
                fontWeight='normal'
                icon={<FaLink />}
              >
                {t('link')}
              </MenuItem>
              <PopoverMessageWrapper
                message={t('warning.no-permission.delete')}
                isDisabled={!hasDeletePermission}
                placement='bottom-end'
              >
                <MenuItem
                  isDisabled={!hasDeletePermission}
                  onClick={() => deleteWorkOrder()}
                  fontSize='sm'
                  fontWeight='normal'
                  icon={<MdDelete />}
                  aria-label='Delete WorkOrder'
                  color='warning.500'
                >
                  {t('actions.delete')}
                </MenuItem>
              </PopoverMessageWrapper>
            </MenuList>
          </Menu>
        </Box>
      </PageTitle>
      <Box pb={2}>
        <Heading size='md' my={4} mx={2}>
          {title}
        </Heading>

        <VStack alignItems='flex-start' my={4} mx={2}>
          <WorkOrderStatusRadio status={status} updateWorkOrderStatus={onChangeWorkOrder} />
        </VStack>

        <Box bg='neutral.0' borderRadius='md' px={4} py={2} mx={2} my={4}>
          {[...fieldOrders]
            .sort((a, b) => a.order - b.order)
            .map((fieldOrder) => {
              return (
                <Box as='div' key={fieldOrder.order}>
                  {fieldOrder.type === 'priority' && priority !== 'none' && (
                    <DetailDisplayItem
                      label={t('priority.title')}
                      data-testid='work-order-detail-priority'
                    >
                      <WorkOrderPriorityLabel priority={priority} />
                    </DetailDisplayItem>
                  )}
                  {fieldOrder.type === 'description' && (
                    <DetailDisplayItem
                      label={t('description')}
                      data-testid='work-order-detail-description'
                      value={description}
                      vertical={true}
                    />
                  )}

                  {fieldOrder.type === 'stoppage' && isDone && stoppage && (
                    <DisplayDownTime startAt={stoppage.startAt} endAt={stoppage.endAt} />
                  )}

                  {fieldOrder.type === 'stoppageReason' && isDone && stoppageReason && (
                    <DetailDisplayItem
                      label={t('stoppage-reason.title')}
                      data-testid='work-order-detail-description'
                      value={stoppageReason.name}
                      vertical={false}
                    />
                  )}

                  {fieldOrder.type === 'assignee' && (
                    <DetailDisplayItem
                      label={t('assignee')}
                      data-testid='work-order-detail-assignee'
                      value={assignees.map((assignee) => assignee.name).join(', ')}
                    />
                  )}
                  {fieldOrder.type === 'group' && accessGroup && (
                    <DetailDisplayItem
                      label={t('group')}
                      data-testid='work-order-detail-group'
                      value={groups.map((group) => group.name).join(',')}
                    />
                  )}

                  {fieldOrder.type === 'asset' && (
                    <DetailDisplayItem
                      data-testid='work-order-detail-asset'
                      label={t('pages.asset')}
                      value={formatAssetName(asset)}
                    />
                  )}

                  {fieldOrder.type === 'due' && (
                    <DetailDisplayItem
                      data-testid='work-order-detail-due-date'
                      label={t('date.due-date')}
                      value={formatDateToMD_or_YYYYMD(dueDate)}
                    />
                  )}
                  {accessProduct && fieldOrder.type === 'product' && (
                    <DetailDisplayItem
                      data-testid='work-order-detail-product'
                      label={t('pages.product')}
                      value={formatProductName(product)}
                    />
                  )}

                  {fieldOrder.type === 'part' && isDone && (
                    <Box my={2}>
                      <WorkOrderPartField
                        workOrderId={id}
                        isEdit={isWorkOrderPartFieldEdit}
                        assetId={asset ? asset.id : undefined}
                        workOrderParts={localWorkOrderParts}
                        onChangeWorkOrderPart={onChangeWorkOrderPart}
                        onUpdatePart={onUpdatePart}
                        isPartRegisterModalOpen={isPartRegisterModalOpen}
                        onPartRegisterModalOpen={onPartRegisterModalOpen}
                        onPartRegisterModalClose={onPartRegisterModalClose}
                      />
                    </Box>
                  )}

                  {fieldOrder.type === 'customField' &&
                    fieldOrder.customFieldId &&
                    generateCustomFieldItem(fieldOrder.customFieldId, 'view')}

                  {fieldOrder.type === 'checkList' && localCheckLists.length > 0 && isDone && (
                    <Box>
                      <FormControl>
                        {localCheckLists.length === 1 ? (
                          <>
                            {targetCheckList && (
                              <SuspenseWithSpinner>
                                <WorkOrderCheckListCustomFieldList
                                  name={targetCheckList.template.name}
                                  description={targetCheckList.template.description}
                                  key={targetCheckList.id}
                                  onChangeCheckListValue={onChangeCheckListValue}
                                  items={targetCheckList.template.items}
                                  checkListFormValue={checkListFormValue}
                                  isEdit={!isDone}
                                />
                              </SuspenseWithSpinner>
                            )}
                          </>
                        ) : (
                          <>
                            <FormLabel fontWeight='bold'>{t('pages.check-list')}</FormLabel>
                            <WorkOrderCheckListField
                              isEdit={false}
                              checkLists={localCheckLists}
                              onChangeCheckLists={onChangeCheckLists}
                              onCheckListClicked={onCheckListClicked}
                            />

                            {targetCheckList && (
                              <SuspenseWithSpinner>
                                <WorkOrderCheckListFormModal
                                  key={targetCheckList.id}
                                  checkListId={targetCheckList.id}
                                  name={targetCheckList.template.name}
                                  description={targetCheckList.template.description}
                                  items={targetCheckList.template.items}
                                  checkListFormValue={checkListFormValue}
                                  isOpen={isCheckListFormOpen}
                                  onOpen={onCheckListFormOpen}
                                  onClose={onCheckListFormClose}
                                  onCheckListUpdated={onCheckListUpdated}
                                  onChangeCheckListValue={onChangeCheckListValue}
                                  isEdit={!isDone}
                                />
                              </SuspenseWithSpinner>
                            )}
                          </>
                        )}
                      </FormControl>
                    </Box>
                  )}
                </Box>
              );
            })}

          {requests.map((request) => {
            const { id, createdBy, createdAt } = request;
            const formattedCreatedAt = formatDateToMDHHmm_or_YYYYMDHHmm(createdAt);
            return (
              <DetailDisplayItem
                key={id}
                label={t('date.requester-date')}
                data-testid='work-order-detail-requester-date'
              >
                <HStack>
                  <Text color='neutral.800' whiteSpace='break-spaces'>
                    {createdBy ? `${createdBy.name}、${formattedCreatedAt}` : formattedCreatedAt}
                  </Text>
                  <Link to={`/requests#${id}`}>
                    <IconButton
                      variant='link'
                      aria-label='request-link'
                      size='xs'
                      icon={<FaExternalLinkAlt />}
                    />
                  </Link>
                </HStack>
              </DetailDisplayItem>
            );
          })}
          <DetailDisplayItem
            label={t('date.creator-date')}
            data-testid='work-order-detail-creator-date'
            value={
              // 作成者の名前は基本入っているはずなので、?をつける必要があるかは不明
              createdBy ? `${createdBy.name}、${formattedCreatedAt}` : formattedCreatedAt
            }
          />
          {formattedCreatedAt !== formattedUpdatedAt && (
            <DetailDisplayItem
              label={t('date.last-updater-date')}
              data-testid='work-order-detail-last-updater-date'
              value={
                // 最終更新者の名前は基本入っているはずなので、?をつける必要があるかは不明
                updatedBy ? `${updatedBy.name}、${formattedUpdatedAt}` : formattedUpdatedAt
              }
            />
          )}
        </Box>

        {[...fieldOrders]
          .sort((a, b) => a.order - b.order)
          .map((fieldOrder) => {
            return (
              <Box as='div' key={fieldOrder.id}>
                {fieldOrder.type === 'stoppage' && !isDone && (
                  <FormProvider {...methods}>
                    <WorkOrderStartEndDatePicker />
                  </FormProvider>
                )}

                {fieldOrder.type === 'stoppageReason' && asset && !isDone && (
                  <Box bg='neutral.0' borderRadius='md' px={4} py={2} mx={2} my={4}>
                    <FormControl data-testid='work-order-form-priority'>
                      <FormLabel>{t('stoppage-reason.title')}</FormLabel>

                      <Controller
                        control={methods.control}
                        name='stoppageReasonId'
                        render={({ field }) => (
                          <WorkOrderStoppageReasonPicker
                            assetId={asset.id}
                            additionalItem={stoppageReason}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </FormControl>
                  </Box>
                )}

                {fieldOrder.type === 'checkList' && localCheckLists.length > 0 && !isDone && (
                  <Box my={4}>
                    <FormControl>
                      {localCheckLists.length === 1 ? (
                        <>
                          {targetCheckList && (
                            <SuspenseWithSpinner>
                              <WorkOrderCheckListCustomFieldList
                                name={targetCheckList.template.name}
                                description={targetCheckList.template.description}
                                key={targetCheckList.id}
                                onChangeCheckListValue={onChangeCheckListValue}
                                items={targetCheckList.template.items}
                                checkListFormValue={checkListFormValue}
                                isEdit={!isDone}
                              />
                            </SuspenseWithSpinner>
                          )}
                        </>
                      ) : (
                        <>
                          <Box bg='neutral.0' borderRadius='md' px={4} py={2} mx={2} my={4}>
                            <FormLabel fontWeight='bold'>{t('pages.check-list')}</FormLabel>
                            <WorkOrderCheckListField
                              isEdit={false}
                              checkLists={localCheckLists}
                              onChangeCheckLists={onChangeCheckLists}
                              onCheckListClicked={onCheckListClicked}
                            />

                            {targetCheckList && (
                              <SuspenseWithSpinner>
                                <WorkOrderCheckListFormModal
                                  key={targetCheckList.id}
                                  name={targetCheckList.template.name}
                                  description={targetCheckList.template.description}
                                  items={targetCheckList.template.items}
                                  checkListFormValue={checkListFormValue}
                                  isOpen={isCheckListFormOpen}
                                  onOpen={onCheckListFormOpen}
                                  onClose={onCheckListFormClose}
                                  onCheckListUpdated={onCheckListUpdated}
                                  onChangeCheckListValue={onChangeCheckListValue}
                                  isEdit={!isDone}
                                />
                              </SuspenseWithSpinner>
                            )}
                          </Box>
                        </>
                      )}
                    </FormControl>
                  </Box>
                )}

                {fieldOrder.type === 'part' && !isDone && (
                  <Box bg='neutral.0' borderRadius='md' px={4} py={2} mx={2} my={4}>
                    <WorkOrderPartField
                      workOrderId={id}
                      isEdit={isWorkOrderPartFieldEdit}
                      assetId={asset ? asset.id : undefined}
                      workOrderParts={localWorkOrderParts}
                      onChangeWorkOrderPart={onChangeWorkOrderPart}
                      onUpdatePart={onUpdatePart}
                      isPartRegisterModalOpen={isPartRegisterModalOpen}
                      onPartRegisterModalOpen={onPartRegisterModalOpen}
                      onPartRegisterModalClose={onPartRegisterModalClose}
                    />
                  </Box>
                )}

                {fieldOrder.type === 'customField' &&
                  fieldOrder.customFieldId &&
                  generateCustomFieldItem(fieldOrder.customFieldId, 'input')}
              </Box>
            );
          })}

        {(hasInputCustomFields || !isDone) && (
          <Flex my={2} px={4} justifyContent='flex-end' width='full'>
            <Button
              variant='solid'
              ml='2'
              colorScheme='primary'
              type='submit'
              onClick={() => onChangeWorkOrder()}
            >
              {t('actions.save')}
            </Button>
          </Flex>
        )}

        <WorkOrderComments
          workOrderId={id}
          workOrderComments={workOrderComments}
          fetchMoreComments={handleFetchMoreComments}
          setUserAvatarBgColor={handleSetUserAvatarBgColor}
          count={commentCount}
          isEditDisabled={isDone}
        />
      </Box>
      {isMobile && (
        <>
          {/* zIndexは、カスタムフィールドのファイルと被った時にボタンが押せなくなるために設定している */}
          <IconButton
            size='lg'
            isRound={true}
            variant='solid'
            aria-label='Done'
            fontSize='18px'
            position='fixed'
            bottom='130px'
            right='3'
            onClick={onWorkOrderPreviousButtonClicked}
            zIndex='10'
            icon={<FaArrowUp />}
          />
          <IconButton
            size='lg'
            isRound={true}
            variant='solid'
            aria-label='Done'
            fontSize='18px'
            position='fixed'
            bottom='70px'
            right='3'
            zIndex='10'
            onClick={onWorkOrderNextButtonClicked}
            icon={<FaArrowDown />}
          />
        </>
      )}

      {/* TODO: Add template and priority fields to the WO pdf */}
      <SuspenseWithSpinner>
        <WorkOrderDetailPDFModal
          show={showPDFModal}
          onClose={onPDFModalClose}
          workOrder={{
            id,
            title,
            description,
            asset,
            product,
            status,
            customFieldTextValues,
            customFieldIntValues,
            customFieldFloatValues,
            customFieldDatetimeValues,
            customFieldSelectValues,
            customFieldDateValues,
            customFieldFileValues,
            customFieldUserValues,
            createdAt,
            dueDate,
            assignees,
            stoppage,
            createdBy,
            updatedAt,
            updatedBy,
            parts: workOrderParts,
            priority,
            // TODO: PDFに表示させないので空配列を渡している。後で修正する。
            checkLists: [],
            templateId,
            isScheduled,
            groups,
            stoppageReason,
          }}
          workOrderComments={workOrderComments}
          userAvatarBgColorMap={userAvatarBgColorMap}
          customFieldFileValues={customFieldFileValues}
          customFields={customFields}
        />
      </SuspenseWithSpinner>
    </Box>
  );
};

export default WorkOrderDetail;
