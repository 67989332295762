import RichMessageEditor, { SubmitInputType } from '@/common/components/RichMessageEditor';
import {
  useAddWorkOrderCommentMutation,
  useRemoveWorkOrderCommentMutation,
  useUpdateWorkOrderCommentMutation,
} from '@/modules/workOrders/graphql/workOrderComments.generated';
import type { IWorkOrderComment } from '@/modules/workOrders/types/workOrderComment';
import { useToast } from '@/utils/atoms/toast';
import useTranslation from '@/utils/i18n/useTranslation';
import { ApolloError } from '@apollo/client';
import { Box, Button, Spacer } from '@chakra-ui/react';
import { FC, useCallback, useEffect, useState } from 'react';
import { INITIAL_COMMENT_LIMIT } from '../WorkOrderDetail';
import CommentList from './CommentList';

type WorkOrderCommentsType = {
  workOrderId: number;
  workOrderComments: IWorkOrderComment[];
  fetchMoreComments: () => Promise<void>;
  setUserAvatarBgColor?: (userId: string, color: string) => void;
  count?: number;
  isEditDisabled: boolean;
};

const commentIdQueryKey = 'commentId';

const WorkOrderComments: FC<WorkOrderCommentsType> = (props: WorkOrderCommentsType) => {
  const {
    workOrderId,
    workOrderComments,
    fetchMoreComments,
    setUserAvatarBgColor,
    count,
    isEditDisabled,
  } = props;
  const { toast } = useToast();
  const { t, t_toasts } = useTranslation();

  const [workOrderCommentRemainCount, setWorkOrderCommentRemainCount] = useState<number>(
    count !== undefined && count > INITIAL_COMMENT_LIMIT ? count - INITIAL_COMMENT_LIMIT : 0
  );
  const [targetCommentId, setTargetCommentId] = useState<number>();
  const [highlightCommentId, setHighlightCommentId] = useState<number>();
  const [addComment] = useAddWorkOrderCommentMutation();
  const [updateComment] = useUpdateWorkOrderCommentMutation();
  const [removeComment] = useRemoveWorkOrderCommentMutation();

  const onNewMessageSubmit = async (
    { text, jsonText, mentionIds, attachments }: SubmitInputType,
    replyCommentId?: number
  ) => {
    if (text === '' && attachments.length === 0) return;
    try {
      const { data } = await addComment({
        variables: {
          newWorkOrderCommentInput: {
            workOrderId,
            comment: text,
            commentLexical: jsonText,
            mentionIds,
            replyCommentId,
            attachments: attachments,
          },
        },
      });
      if (!data) throw new Error('Data Error');
    } catch (error) {
      if (error instanceof ApolloError) {
        toast({ title: error.message, status: 'error', duration: 9000, isClosable: true });
      } else {
        throw error;
      }
    }
  };

  const onCommentEditSubmit = async (
    commentId: number,
    { text, jsonText, mentionIds, attachments, deletedAttachmentFileIds }: SubmitInputType
  ) => {
    try {
      const { data } = await updateComment({
        variables: {
          updateWorkOrderCommentInput: {
            id: commentId,
            comment: text,
            commentLexical: jsonText,
            attachments,
            deletedAttachmentFileIds,
            mentionIds,
          },
        },
      });

      if (!data) throw new Error('Data Error');
    } catch (error) {
      if (error instanceof ApolloError) {
        toast({ title: error.message, status: 'error', duration: 9000, isClosable: true });
      } else {
        throw error;
      }
    }
  };

  const onCommentDelete = async (id: number) => {
    try {
      await removeComment({ variables: { id } });
    } catch (error) {
      if (error instanceof ApolloError) {
        toast({ title: error.message, status: 'error', isClosable: true });
      } else {
        throw error;
      }
    }
  };

  const scrollToComment = useCallback((commentId: string) => {
    const element = document.getElementById(`comment-id-${commentId}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const onMoreCommentClick = useCallback(async () => {
    await fetchMoreComments();
    setWorkOrderCommentRemainCount(0);
  }, [fetchMoreComments]);

  const onGetCommentLinkClick = useCallback(
    async (commentId: number) => {
      // NOTE: This is a workaround for Safari
      const url = new URL(
        `${window.location.origin}${window.location.search}${window.location.hash}`
      );

      // NOTE: To ensures param: 'comment' is not duplicated
      const params = new URLSearchParams(url.search);

      params.set(commentIdQueryKey, commentId.toString());

      url.search = params.toString();
      url.hash = workOrderId.toString();

      try {
        await navigator.clipboard.writeText(url.toString());
        toast({
          title: t_toasts('success.copied-link'),
          status: 'success',
        });
      } catch (_e) {
        toast({
          title: t_toasts('failed.copied-link'),
          status: 'error',
        });
      }
    },
    [toast, workOrderId, t_toasts]
  );

  const onGoToCommentClick = useCallback(
    async (commentId: number) => {
      let element = document.getElementById(`comment-id-${commentId}`);
      if (!element) {
        await onMoreCommentClick();
        element = document.getElementById(`comment-id-${commentId}`);
      }

      if (element) {
        // FIXME: If the comments rendering is not finished, it will not be highlighted.
        scrollToComment(commentId.toString());
        setHighlightCommentId(commentId);
        setTimeout(() => {
          setHighlightCommentId(undefined);
        }, 5000);
      }
    },
    [onMoreCommentClick, scrollToComment]
  );

  useEffect(() => {
    const getCommentFromUrl = () => {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      return params.get(commentIdQueryKey);
    };

    const elementId = getCommentFromUrl();
    if (!elementId) return;
    if (workOrderComments.length === 0) return;

    const index = workOrderComments.findIndex((comment) => comment.id === Number(elementId));

    if (index === -1) {
      onMoreCommentClick();
    } else {
      if (targetCommentId) return;
      setTargetCommentId(workOrderComments[index].id);
      scrollToComment(elementId);
    }
  }, [onMoreCommentClick, scrollToComment, targetCommentId, workOrderComments]);

  return (
    <Box
      p={{ base: 2, md: 4 }}
      borderTop='1px'
      borderTopColor='gray.200'
      position='relative'
      backgroundColor='neutral.0'
    >
      {workOrderCommentRemainCount > 0 && (
        <Button colorScheme='primary' variant='link' my='3' onClick={onMoreCommentClick}>
          {t('comment.other-comments', { commentCount: workOrderCommentRemainCount })}
        </Button>
      )}
      <CommentList
        comments={workOrderComments}
        onCommentsItemEdit={onCommentEditSubmit}
        onCommentsItemDelete={onCommentDelete}
        onCommentsItemReply={onNewMessageSubmit}
        onCommentsItemGetLink={onGetCommentLinkClick}
        onGoToCommentClick={onGoToCommentClick}
        setUserAvatarBgColor={setUserAvatarBgColor}
        targetCommentId={targetCommentId}
        highlightCommentId={highlightCommentId}
        isEditDisabled={isEditDisabled}
      />
      <Spacer height='4'></Spacer>
      <RichMessageEditor placeholder={t('comment.placeholder')} submit={onNewMessageSubmit} />
    </Box>
  );
};

export default WorkOrderComments;
